var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading",attrs:{"id":_vm.$options.name}},_vm._l((_vm.hotels),function(hotel,hotel_index){return _c('div',{key:_vm._uid+'_heading_'+hotel_index},[_c('div',{staticClass:"heading__wrap"},[_c('div',{staticClass:"heading__element heading__element--first"},[_c('div',{staticClass:"d-flex align-items-center"},[(hotel.hotel_data.preferred)?_c('vb-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Pensato per offrire un ottimo servizio e un buon rapporto qualità-prezzo.'),expression:"'Pensato per offrire un ottimo servizio e un buon rapporto qualità-prezzo.'",modifiers:{"hover":true,"top":true}}],staticClass:"heading__icon heading__icon--like",attrs:{"name":'like',"color":_vm.sass('secondary'),"size":18}}):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"heading__title",domProps:{"innerHTML":_vm._s(hotel.hotel_data.name)}}),(hotel.hotel_data.class !== 0)?_c('b-form-rating',{staticClass:"heading__star",attrs:{"no-border":"","inline":"","readonly":"","size":"md","variant":"secondary","value":hotel.hotel_data.class}}):_vm._e()],1)],1),_c('p',{staticClass:"heading__address"},[_c('vb-icon',{staticClass:"heading__icon mr-1",attrs:{"name":'symbol_lb',"color":_vm.sass('primary'),"size":14}}),_c('span',{domProps:{"innerHTML":_vm._s(hotel.hotel_data.address + ', ' + hotel.hotel_data.zip + ' ' + hotel.hotel_data.city)}}),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal_map'),expression:"'modal_map'"}],staticClass:"heading__address-map",domProps:{"innerHTML":_vm._s('vedi mappa')}})],1)]),_c('div',{staticClass:"heading__element heading__element--second"},[_c('score',{staticClass:"heading__score",attrs:{"hotel":hotel.hotel_data,"direction":"row"}}),_c('router-link',{attrs:{"to":{ name: 'detail', hash: '#rooms' }}},[_c('vb-button',{staticClass:"semibold",attrs:{"vb_options":{
                text: 'Prenota Ora',
                variant: 'info',
                size: 'sm',
              }}})],1)],1)]),_c('vb-modal',{attrs:{"vb_options":{
      id: 'modal_map',
      size: 'xl',
      title: hotel.hotel_data.name,// + ' - ' + hotel.hotel_data.address,
      hide_footer: true,
    }}},[_c('detail-google-map',{attrs:{"map_markers":_vm.map_markers([_vm.detail_hotel], _vm.availability, _vm.sass),"map_location":{
          latitude: _vm.number(_vm.detail_hotel.location.latitude),
          longitude: _vm.number(_vm.detail_hotel.location.longitude),
        }}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }