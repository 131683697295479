<template>
  <b-col md="12">
    <section class="hotel" v-if="confirm.hd">
      <h3 class="hotel__name" v-html="confirm.hd.name" />

      <div class="hotel__info">
        <div class="hotel__info-item">
          <vb-icon class="hotel__info-icon mr-1" :name="'pin'" :color="sass('primary')" :size="14" />
          <span class="hotel__info-label" v-html="confirm.hd.address + ', ' + confirm.hd.zip + ' ' + confirm.hd.city + ', ' + confirm.hd.country" />
        </div>
        <div class="hotel__info-item">
          <vb-icon class="hotel__info-icon mr-1" :name="'phone'" :color="sass('primary')" :size="14" />
          <span class="hotel__info-label" v-html="confirm.hd.phone" />
        </div>
        <div class="hotel__info-item" v-if="confirm.hd.email !== ''">
          <vb-icon class="hotel__info-icon mr-1" :name="'email'" :color="sass('primary')" :size="14" />
          <span class="hotel__info-label" v-html="confirm.hd.email" />
        </div>
      </div>

      <div class="hotel__wrap">
        <div class="hotel__room" v-for="(room, room_index) in confirm.bookingVoucher.orderData.details.rooms" v-bind:key="_uid + room_index">

          <span class="hotel__room-index" v-html="'Camera ' + (room_index + 1)" />

          <div class="hotel__room-mealplan">
            <vb-icon v-if="Object.values(room.mealplan).reduce((count, value) => count + (value.included ? 1 : 0), 0)" class="hotel__room-mealplan_icon" :name="'cutlery'" :color="sass('success')" :size="14" />

            <template v-for="(value, key) in room.mealplan">
              <div class="hotel__room-meal"
                   v-if="value.included" :key="_uid + '_mealplan_' + key"
              >
                <span class="hotel__room-meal_name" v-html="get_mealplan(key).name" />
                <span class="hotel__room-meal_price" v-if="value.price_per_person_per_night" v-html="'(€ ' + value.price_per_person_per_night + ' a persona per notte)'" />
              </div>
            </template>
          </div>

        </div>
      </div>

      <div class="hotel__key">
        <key-info class="recap__info-key" :hotel="confirm.bookingVoucher.orderData.details" />
      </div>
    </section>
  </b-col>
</template>

<script>
import Vue from "vue";
import KeyInfo from '@booking/components/blocks/key-info.vue'
import { get_mealplan } from "@booking/resources"

export default {
  name: 'hotel-info',

  components: {
    'key-info': KeyInfo,
  },

  props: {
    confirm: Object
  },

  data() {
    return {
      config: Vue.prototype.$config,
    }
  },

  computed: {
  },

  created() {
    this.get_mealplan = get_mealplan
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .hotel {
    margin-top: $grid-gutter-width*2;

    &__name {
      font-size: $font-size-xl;
      margin-bottom: 10px;
    }

    &__info {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-300;

      @media print {
        border-bottom: 0;
      }

      &-item {
        display: flex;
        align-items: center;
        margin-right: $grid-gutter-width/2;
      }

      &__label {
        font-size: $font-size-lg;
      }
    }

    &__wrap, &__key {
      margin-top: $grid-gutter-width/2;
      background-color: $white;
      padding: 20px;
      border-radius: 10px;

      @media print {
        display: none;
      }

      &:empty {
        display: none;
      }
    }

    &__room {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray-200;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      &-index {
        font-weight: $vb-font-weight-semibold;
        margin-right: 20px;
      }

      &-mealplan {
        display: flex;
        align-items: center;

        &_icon {
          margin-right: 6px;
        }
      }

      &-meal {
        margin-right: 5px;
        color: $success;

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:after {
          content: ',';
        }

        &_price {
          font-size: $font-size-md;
          margin-left: 3px;
        }
      }
    }

  }

</style>