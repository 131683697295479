<template>
  <div class="occupancy-icons">
    <div v-if="occupancy.adults > 4" class="occupancy-icons__adults">
      <vb-icon class="occupancy-icons__icon occupancy-icons__icon--adults" :name="'user'" :color="sass('dark')" :size="16" />
      <sup class="text--sm" v-html="' x' + occupancy.adults" />
    </div>
    <div v-else v-for="index_adults in occupancy.adults" :key="_uid + '_adults_' + index_adults" class="occupancy-icons__adults">
      <vb-icon class="occupancy-icons__icon occupancy-icons__icon--adults" :name="'user'" :color="sass('dark')" :size="16" />
    </div>
    <vb-icon class="occupancy-icons__icon"
             v-for="index_child in occupancy.children"
             :key="_uid + '_child_' + index_child" :name="'user'" :color="sass('dark')" :size="14"
    />
  </div>
</template>

<script>
export default {
  name: 'occupancy-icons',

  components: {
  },

  props: {
    occupancy: Object
  },

  data() {
    return {
    }
  },

  computed: {

  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .occupancy-icons {
    $this_occupancy: &;

    display: flex;
    align-items: baseline;
    border-radius: $border-radius-lg;
    padding: 4px 2px;
    background-color: $wild-sand;

    sup {
      margin: 10px 2px 0 0;
    }

    &__adults {
      display: flex;
      align-items: center;
    }

    &__icon {
      display: flex;
      align-items: center;

      @at-root #{$this_occupancy}__adults + & {
        position: relative;
        padding-left: 10px;
        margin-left: 3px;

        &:before {
          content: '+';
          position: absolute;
          left: 0;
        }
      }

      &--info {
        margin: 2px 0 0 5px;
      }
    }
  }

</style>