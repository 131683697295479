<template>
  <section class="booking-engine" ref="booking-engine" v-click-outside="close_datepicker">
    <b-row>
      <!-- Location (autocomplete) -->
      <b-col md="4">
        <div class="vb-form">
          <button ref="hidden_input" class="focus-dump-button" />
          <!--serve per togliere il focus dagli autocomplete dopo il click, così poi al focus si seleziona di nuovo tutto il testo ed è più semplice cancellarlo-->
          <autocomplete
            base-class="booking-engine--autocomplete"
            ref="autocomplete"
            :key="location.id"
            :search="on_search_location"
            :placeholder="autocomplete_placeholder"
            :debounce-time="500"
            @submit="on_submit_location">
            <template #default="{ inputProps, inputListeners, results, noResults, focused, resultProps, resultListProps, resultListListeners }">
              <div class="booking-engine--autocomplete--form">
                <div class="booking-engine--autocomplete--icon">
                  <vb-icon :name="'doublebed'" :color="sass('primary')" :size="20" />
                </div>
                <input
                  v-if="autocomplete_ready"
                  type="search"
                  class=""
                  ref="autocomplete-input"
                  :class="[
                    'vb-input form-control',
                    { 'autocomplete-input-no-results': noResults },
                    { 'autocomplete-input-focused': focused },
                  ]"
                  v-bind="inputProps"
                  v-on="inputListeners"
                  @focus="$event => {
                    autocomplete_focus = true
                    scroll_into_view()
                    $event.target.select()
                  }"
                  @blur="() => {
                    autocomplete_focus = false
                    autocomplete_blur
                  }"
                />
                <div v-if="autocomplete_searching" class="booking-engine--autocomplete--loading">
                  <loader />
                </div>
              </div>            
              
              <b-row v-if="results">
                <b-col>
                  <ul v-bind="resultListProps" v-on="resultListListeners" class="booking-engine--autocomplete-result-wrap">
                    <li
                      v-for="(result, index) in results"
                      :set-level="level = (result.level !== undefined ? result.level-1 : 0)"
                      :style="result.matchLevel == 'noresults' ? 'pointer-events: none' : ''"
                      :key="result.label"
                      :data-type="result.type"
                      v-bind="resultProps[index]">
                      <div v-if="result.matchLevel == 'noresults'" v-html="result.label" />
                      <div v-else class="booking-engine--autocomplete-result-item" :style="'padding-left:calc('+(result.level ? result.level-1 : 0)+'*0.5rem)'">
                        <div class="booking-engine--autocomplete-result-icon">
                          <vb-icon :name="get_type_icon(result.type)" :color="sass('dark')" :size="14" />
                        </div>
                        <div class="booking-engine--autocomplete-result-name" v-html="result.name"/>
                        <div class="booking-engine--autocomplete-result-label" v-html="result.label"/>
                      </div>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </template>
          </autocomplete>
        </div>
      </b-col>

      <!-- Dates (checkin-checkout) -->
      <b-col md="3" @click="close_occupancy">
        <div class="booking-engine--dates">
          <div class="booking-engine--dates--form cursor-pointer" @click.stop="datepicker_open = !datepicker_open">
            <div class="booking-engine--dates--icon">
              <vb-icon :name="'calendar'" :color="sass('primary')" :size="20" />
            </div>
            <b-form-input
              ref="input-checkin"
              id="input-checkin"
              :placeholder="dates_placeholder"
              class="vb-input"
            />
          </div>
          <div v-show="datepicker_open" class="vc-wrapper">
              <v-date-picker
                is-range
                v-model="range"
                :key="range.start+'-'+range.end"
                :min-date="min_date"
                :max-date="max_date"
                @input="(d) => on_submit_date(d)"
              />
            <div class="picker-alert">
              <b-alert show variant="warning" size="xs">
                Le <b>prenotazioni per il giorno successivo</b> possono essere effettuate <b>entro le ore 14</b>.
              </b-alert>
            </div>
            </div>
        </div>
      </b-col>

      <!-- Occupancy -->
      <b-col md="3" xl="4" @click="close_datepicker">
        <div class="booking-engine--occupancy" v-click-outside="close_occupancy">
          <div class="booking-engine--occupancy--form cursor-pointer" @click="occupancy_open = !occupancy_open">
            <b-form-input
              ref="input-occupancy"
              id="input-occupancy"
              :placeholder="occupancy_placeholder"
              v-bind="occupancy"
              class="vb-input"
            />
            <div class="booking-engine--occupancy--icon">
              <vb-icon :name="'occupants'" :color="sass('primary')" :size="20" />
            </div>
            <div class="booking-engine--occupancy--arrow">
              <vb-icon :name="'angle_small_down'" :color="sass('primary')" :size="24" />
            </div>              
          </div>
          <occupancy
            v-show="occupancy_open"
            v-bind="occupancy"
            @updated="e => {
              this.$store.commit('occupancy', e.occupancy)
            }"
            :occupancy="occupancy"
            :max_children_per_room="get_config('guiSettings.occupancy.maxChildrenPerRoom', 4)"
            :max_adults_per_room="get_config('guiSettings.occupancy.maxAdultsPerRoom', 4)"
            :max_rooms="get_config('guiSettings.occupancy.maxRooms', 1)"
            :max_pax="get_config('guiSettings.occupancy.maxPax', 24)">
              <template v-slot:title v-if="get_config('guiSettings.occupancy.maxRooms', 1) > 1">
                <p class="semibold d-inline-flex align-items-center cursor-pointer" v-b-popover.hover.top.html="'Il Beneficiario della prestazione (Viaggiatore principale) potrai essere tu, in qualità di titolare del credito welfare, o dovrai, in alternativa, indicare un familiare. Con &quot;familiare&quot; si intende una persona così come definita dal combinato disposto dell\'Art. 433 del Codice Civile e dell\'Art. 12 del D.P.R. del 22.12.1986 n. 917 e smi (TUIR).<br><br>Ti ricordiamo che ai sensi dell\'art. 76 del DPR 445/2000, le dichiarazioni mendaci, la falsità e l\'uso di fatti falsi sono punite ai sensi del Codice penale.'">
                  Quante camere?
                </p>
              </template>
              <template v-slot:disclaimer>
                <p class="text--sm mt-0 mb-0">
                  Età dei bambini il giorno del check-out
                </p>
              </template>
              <template v-slot:footer>
                <div class="occupancy--slot--footer">
                  <vb-button
                    @click.stop="close_occupancy"
                    :vb_options="{
                      text: 'Fatto',
                      variant: 'primary',
                      size: 'sm',
                    }"/>
                </div>
              </template>
          </occupancy>
        </div>
      </b-col>

      <!-- Search action -->
      <b-col md="2" xl="1">
        <div class="booking-engine--search">
          <div class="vb-form text-center">
            <b-form-group
              label-for="input-autocomplete"
              label=""
              label-class="vb-name">
              <vb-button
                @click="search"
                :vb_options="{
                  text: (searching ? 'Attendi...' : 'Cerca'),
                  disabled: searching || !search_enabled,
                  variant: 'info',
                }"/>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import Occupancy from '@components/blocks/occupancy.vue'
import { service_handler } from '@services'
import { encode_parameters, reverse_date, is_mobile, get_type_icon } from '@utilities'
import moment from 'moment'
import Fuse from 'fuse.js'

export default {
  name: 'liveBK-booking-engine',

  components: {
    'occupancy': Occupancy,
  },

  props: {
  },

  computed: {
    // global state keys
    ...mapState([
      'occupancy',
      'searching',
    ]),

    // module state keys
    ...mapState(
      'livebk', [
        'location',
        'checkin',
        'checkout',
      ]
    ),

    search_params() {
      return {
        checkin: this.checkin,
        checkout: this.checkout,
        suggestion: this.location,
        occupancy: this.occupancy,
      }
    },

    autocomplete_placeholder() {
      let default_placeholder = 'Dove vuoi andare?'
      if (this.autocomplete_focus) return default_placeholder
      return this.location.label ? (this.location.label.charAt(0).toUpperCase() + this.location.label.slice(1).toLowerCase()) : default_placeholder
    },

    occupancy_placeholder() {
      let placeholder = '',
        adults = 0,
        children = 0
      this.occupancy.forEach(room => {
        adults += room.adults
        children += room.children.length
      })
      placeholder = adults + ' adult' + (adults == 1 ? 'o' : 'i')
      if (children) placeholder += '  •  '+children + ' bambin' + (children == 1 ? 'o' : 'i')
      if (this.occupancy.length > 1) placeholder += '  •  ' + this.occupancy.length + ' camere'
      return placeholder
    },

    dates_placeholder() {
      if (this.checkin == '') return 'Scegli le date'
      let checkin = moment(this.checkin, "YYYY-MM-DD").format('ddd D') + ' ' + moment(this.checkin, "YYYY-MM-DD").format('MMMM').slice(0,3)
      let checkout = moment(this.checkout, "YYYY-MM-DD").format('ddd D') + ' ' + moment(this.checkout, "YYYY-MM-DD").format('MMMM').slice(0,3)
      return checkin + ' - ' + checkout
    },

    search_enabled() {
      if (this.location.label == '') return false
      if (this.checkin == '') return false
      if (this.checkout == '') return false
      return true
    }
  },

  created() {
    moment.locale(navigator.language.split('-')[0])
    this.encode_parameters = encode_parameters
    this.reverse_date = reverse_date
    this.is_mobile = is_mobile
    this.get_type_icon = get_type_icon
  },

  data() {
    let min_days = this.get_config().guiSettings.minDays,
      checkin_delay = (moment().hour() > 13 ? min_days+1 : min_days),
      calendar_min_date = moment().add(checkin_delay, 'd').format('YYYY[-]MM[-]DD'),
      calendar_max_date = moment().add(1, 'y').format('YYYY[-]MM[-]DD')
    return {
      loading: false,
      autocomplete_focus: false,
      autocomplete_ready: true,
      autocomplete_searching: false,
      datepicker_open: false,
      datepicker_keep: false,
      occupancy_open: false,
      calendar_key: 0,
      calendar_config: {
        type: 'string',
        mask: 'YYYY-MM-DD',// Uses 'iso' if missing
      },
      min_date: calendar_min_date,
      max_date: calendar_max_date,
      dragValue: null,
      range: {
        /*
        start: new Date(2018, 0, 8),
        end: new Date(2018, 0, 12),
        */
      },
      suggestions: [],
    }
  },

  mounted() {
    /* eventuale gestione dell'evento 'suggestion' triggerato da altri componenti
    window.eventBus.$off('suggestion')
    window.eventBus.$on('suggestion', () => {
      this.search()
    })
    */    
    if (this.checkin && this.checkout) {
      this.range = {
        start: moment(this.checkin, 'YYYY-MM-DD')._d,
        end: moment(this.checkout, 'YYYY-MM-DD')._d,
      }
    }
  },

  methods: {
    scroll_into_view() {
      this.$refs['booking-engine'].scrollIntoView({ behavior: 'smooth' })
    },
    close_occupancy() {
      this.occupancy_open = false
    },
    close_datepicker() {
      if (this.datepicker_keep) {
        this.datepicker_keep = false
        return
      }
      this.datepicker_open = false
    },
    hide_suggestions() {
      this.$refs.hidden_input.click()
    },
    date_check(day) {
      return new Date(day.id) >= (new Date())
    },
    autocomplete_blur() {
      this.$refs['autocomplete'].$data.value = ''
    },
    encode_search_params() {
      return encode_parameters(this.search_params)
    },
    async on_search_location(q) {
      
      // condizioni uscita chiamata autocomplete
      if (!q) return []
      if (q == '') return []
      if (q.length < 3) return []

      this.autocomplete_searching = true

      let
        config = this.get_config(),
        services = {
          here: 'here.autocomplete',
          geo: 'searchByGeo',
          property: 'searchByProperty',
          //in_salabam: 'searchInSalabamEndpoint',
        },
        params = {
          apikey: config.hereApiKey,
          q: q,
          lang: 'it',
          limit: 5, // 5 default
          token: config.token, // local api
        },
        search_services = [],
        suggestions = [],
        fused_suggestions = []

      
      // here
      if(config.searchSettings.useHere){
        search_services.push(
          service_handler(
            services.here,
            {
              params,
              service_name: services.here,
            },
            {
              modal: false,
              api_method: 'get',
              external: true,
            },
          )
        )
      }

      // geo
      if(config.searchSettings.useGeo){
        search_services.push(
          service_handler(
            services.geo,
            {
              q,
              service_name: services.geo,
            },
            {
              modal: false
            },
          )
        )        
      }

      // property
      if (config.searchSettings.useProperty) {
        search_services.push(
          service_handler(
            services.property,
            {
              q,
              service_name: services.property,
            },
            {
              modal: false
            },
          )
        )
      }

      // TODO
      // inSalabam
      //if(Vue.prototype.$config.searchSettings.useInSalabam){
      //  search_services.push(timedAxiosGet(this.searchInSalabamEndpoint, {params}, Vue.prototype.$config.searchSettings.axiosTimeout))
      //} // search use inSalabam
      
      await Promise.all(search_services).then(results => {
        results.forEach(result => {

          let search_service = ''
          if (result.config.service_name == services.here) search_service = services.here
          else if (JSON.parse(result.config.data).service_name == services.geo) search_service = services.geo
          else if (JSON.parse(result.config.data).service_name == services.property) search_service = services.property

          switch (search_service) {
            case services.geo:
            case services.property:
            //case services.in_salabam:
              result.data.suggestions.forEach(suggestion => {
                suggestion.type = suggestion.matchLevel
                suggestion.sorting = 0
                if(suggestion.countryCode == 'CUB' || suggestion.countryCode == 'CU') return false // escludo cuba
                suggestions.push(suggestion)
              })
              break

            case services.here:
              result.data.items.forEach((suggestion, index) => {
                if(suggestion.address.countryCode == 'CUB') return // escludo cuba
                let i = index+1,
                  temp_label = '',
                  suggestion_sorting = 0

                switch (suggestion.resultType) {
                  case "houseNumber":
                    suggestion.matchLevel = suggestion.houseNumberType  // PA, interpolated
                    break
                  case "locality":
                    suggestion.matchLevel = suggestion.localityType  // postalCode, district, subdistrict, city
                    switch (suggestion.localityType) {
                      case "city":
                        suggestion_sorting = 100
                        temp_label = `Città di ${suggestion.address.city}`
                        if(suggestion.address.state) temp_label += ', ' + suggestion.address.state
                        break
                      case "district":
                      case "subdistrict":
                        suggestion_sorting = 50
                        temp_label = `Quartiere ${suggestion.address.district}, ${suggestion.address.city}`
                        break
                      case "postalCode":
                        suggestion.name = suggestion.title
                        temp_label = suggestion.title
                        break
                    }
                    break
                  case "administrativeArea":
                    suggestion.matchLevel = suggestion.administrativeAreaType  // county, state, country
                    switch (suggestion.administrativeAreaType) {
                      case "county":
                        suggestion_sorting = 90
                        temp_label = `Provincia di ${suggestion.address.county}`
                        if(suggestion.address.state) temp_label += ', ' + suggestion.address.state
                        break
                      case "state":
                        suggestion_sorting = 10
                        temp_label = `Regione ${suggestion.address.state}`
                        break
                      case "country":
                        temp_label = suggestion.address.country
                        break
                    }
                    break
                  case "addressBlock":
                    suggestion.matchLevel = suggestion.addressBlockType  // block, subblock
                    break

                  //case "postalCodePoint":
                  //case "street":
                  //case "intersection":
                  //case "place":
                  default:
                    suggestion.matchLevel = suggestion.resultType
                    suggestion.name = suggestion.title
                    temp_label = suggestion.title
                    break
                }

                suggestion.name = suggestion.address[suggestion.matchLevel]
                suggestion.type = suggestion.matchLevel

                if(suggestion.address.countryCode == 'ITA'){
                  suggestion_sorting += 100 // prima ITA (principale mercato)
                } else {

                  //temp_label += ' (' + suggestion.address.county + ', ' + suggestion.address.countryName + ')'
                  let countyStr = (suggestion.address.county)? suggestion.address.county + ',' : ''
                  let countryStr = (suggestion.address.countryName)? ' ' + suggestion.address.countryName : ''
                  temp_label += "(" + countyStr + countryStr + ")"

                }

                // se si cambia la posizione di label
                // o la struttura di suggestion
                // cambiare il serializer dell'autocomplete
                suggestion.label = temp_label

                // sottraendo i faccio si che a parità di suggestion_sorting
                // vengano comunque mostrati i suggerimenti secondo l'ordine ritornato dall'api
                suggestion.sorting = suggestion_sorting - i
                
                suggestions.push(suggestion)
              })
              break
            
            default:
              break
          }
        })

        const options = {
          // isCaseSensitive: false,
          // includeScore: false,
          // shouldSort: true,
          // includeMatches: false,
          // findAllMatches: false,
          minMatchCharLength: 3,
          // location: 0,
          threshold: 0.3, //[0,...,1] default 0.6 (0 => perfect match, 1 => match anything)
          // distance: 100,
          // useExtendedSearch: false,
          // ignoreLocation: false,
          // ignoreFieldNorm: false,
          // fieldNormWeight: 1,
          keys: [
            {
              name: "fusehint",
              weight: 7,
            },
            {
              name: "address.city",
              weight: 3,
            },
            {
              name: "name",
              weight: 2,
            },
            "label",
          ]
        }
        
        let fuse = new Fuse(suggestions, options)
        
        fused_suggestions = fuse.search(q).map(s => { return s.item })

        if(fused_suggestions.length == 0){
          fused_suggestions.push({
            type: 'msg',
            name: 'nessun risultato',
            label: 'nessun risultato',
            matchLevel: 'noresults',
          })
        }      
      })

      this.autocomplete_searching = false
      return fused_suggestions
      
    },
    search() {
      // site specific logic
      // ...

      // shared logic
      this.$store.commit('livebk/current_search', this.encode_search_params())
      this.$store.commit('livebk/last_search', this.encode_search_params())
      this.$store.commit('searching', true)
      this.$store.commit('livebk/trigger_search', true)
      this.$emit('search', {
        reset_page_filters: true,
        reset_advanced_filters: true,
        reset_search_results: true,
      })
    },
    on_submit_date(dates) {
      this.datepicker_keep = true
      let checkin = moment(dates.start).format('YYYY[-]MM[-]D')
      let checkout = moment(dates.end).format('YYYY[-]MM[-]D')
      if(checkin == checkout) {
        checkout = moment(dates.end).add(1, 'days').format('YYYY[-]MM[-]D')
        this.range.end = moment(dates.end).add(1, 'days').format('YYYY[-]MM[-]D')
      }
      this.close_datepicker()
      this.$store.commit('livebk/checkin', checkin)
      this.$store.commit('livebk/checkout', checkout)
    },
    on_submit_location(suggestion) {
      this.autocomplete_searching = true
      this.hide_suggestions()
      this.$store.commit('livebk/current_search', this.encode_search_params())

      // serve ad evitare che al click sulla suggestion già attiva compaia [Object Object] nell'input testuale
      this.$nextTick(() => {
        this.$refs['autocomplete'].$data.value = ''
        this.autocomplete_searching = false
        // fix per mancato aggiornamento placeholder su ipad
        this.$nextTick(() => {
          this.autocomplete_focus = false
        })
      })

      // es. suggestion "nessun risultato"
      if(suggestion.type == 'msg') return

      let location = {
        id: suggestion.id,
        label: suggestion.label,
        matchLevel: suggestion.matchLevel,
        latitude: '',
        longitude: '',
      }

      // se ho già le coordinate NON serve la chiamata al geocode
      // è il caso di searchByProperty (in cui ho id che può essere sia positivo sia negativo), e searchByGeo (in cui l'id non c'è per niente)
      // la chiamata a lookup va fatta solo nel caso di suggestion here, che ha un id del tipo "here:cm:namedplace:20116469"
      if (!suggestion.id.includes("here")) {
        this.$store.commit('livebk/location', {
          ...location,
          matchLevel: suggestion.matchLevel,
          latitude: suggestion.latitude,
          longitude: suggestion.longitude,
        })
        return
      }

      // se NON ho ancora le coordinate devo interrogare 
      let config = this.get_config(),
        params = {
          apikey: config.hereApiKey,
          language: 'it',
          id: suggestion.id,
        }
        
      service_handler(
        'here.lookup',
        {
          params,
          service_name: 'here.lookup',
        },
        {
          modal: false,
          api_method: 'get',
          external: true,
        },
      )
      .then(
        (success) => {
          this.log('[here.lookup] - success')
          this.$store.commit('livebk/location', {
            ...location,
            latitude: success.data.position.lat,
            longitude: success.data.position.lng,
          })
        },
        (error) => {
          this.log('[here.lookup] - error')
          this.log(error.response)
          window.eventBus.$emit('got_toast_notification', {
            text: "Ops! Abbiamo problemi tecnici con questa destinazione e ci stiamo lavorando. Fai un nuovo accesso e cerca una nuova destinazione.",
            //timer: 999999, // default 10000
            variant: "danger",
          })
        }
      )
    }
  }
}
</script>

<style lang="scss">
/* rimosso lo scoped: non permetteva di modificare lo style delle tabs */
.booking-engine {
  $gutter: 20px;
  @include make-grid-columns($columns: $grid-columns, $gutter: $gutter, $breakpoints: $grid-breakpoints);
  .row {
    margin-left: -$gutter/2;
    margin-right: -$gutter/2;
  }
  position: relative;
  z-index: 100;
  max-width: 1160px;
  margin: 0 auto;
  margin-top: 1.5rem;
  background: $secondary;
  padding: $gutter/2;
  border-radius: $border-radius;

  &>.row>* {
    position: relative;
    &:nth-child(1) { z-index: 4;}
    &:nth-child(2) { z-index: 3;}
    &:nth-child(3) { z-index: 2;}
    &:nth-child(4) { z-index: 1;}
    &:not(:last-child) {
      margin-bottom: $gutter/2;
    }
    @include media-breakpoint-up(md) {
      &:nth-child(1n) {
        margin-bottom:0;
      }
    }
  }
  
  &--panel {
    background-color: $white;
    position: absolute;
    width: 100%;
    top: 100%;
    margin: $gutter/4 0 0 0;
    padding: $gutter/2 $gutter;
    border: 1px solid $gray-200;
    box-shadow: $box-shadow-sm;
    border-radius: $btn-border-radius;
  }

  @include media-breakpoint-up(md) {
    & > .row {
      & > *:nth-child(2),
      & > *:nth-child(3),
      & > *:nth-child(4) {
        padding-left: 0;
      }
    }
  }

  // Focus Button
  .focus-dump-button {
    opacity: 0;
    position: absolute;
    left: -999999px;
    top: -999999px;
  }

  // Input
  .form-group {
    margin-bottom: 0;
  }

  input {
    &::placeholder {
      //text-transform: capitalize;
      font-size: $font-size-base;
      //color: $primary;
      //font-weight: $vb-font-weight-semibold;
    }
    &:focus {
    }
  }

  &--autocomplete--form input::placeholder {
    color: $primary;
  }

  &--autocomplete--loading {
    position: absolute;
    top: 50%;
    right: $grid-gutter-width/4;
    transform: translateY(-50%);
    background-color: $white;
  }

  #input-occupancy,
  #input-checkin {
    pointer-events: none;
  } 

  // icons
  &--occupancy,
  &--dates,
  &--autocomplete {
    &--form {
      position: relative;
      input {
        padding-left: $gutter * 2;
      }
    }
    &--icon {
      position: absolute;
      top:50%;
      left:$gutter/2;
      height:26px;
      transform: translateY(-50%);
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--arrow {
      position: absolute;
      top:50%;
      right:$gutter/2;
      height:24px;
      transform: translateY(-50%);
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;      
    }
  }

  &--search {
    .vb-btn {
      width: 100%;
    }
  }

  &--autocomplete {
    // classi generate con vue-autocomplete
    &-result {
      padding: $gutter/4 $gutter;
      position:relative;

      &:not(:last-child) {
        &:after {
          content:"";
          position:absolute;
          bottom:0;
          left:$gutter;
          right:$gutter;
          height: 1px;
          background: $gray-200;
        }
      }

      &-item {
        padding: $gutter/4 0;
        cursor: pointer;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        * {
          color: inherit;
          transition: $transition-base;
        }
        &:hover * {
          color: $info;
          --color: #{$info};
        }
      }

      &-icon {
        margin-right: $gutter/4;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-name {
        font-weight: $vb-font-weight-semibold;
        color: $dark;
      }

      &-label {
        min-width:100%;
        font-size: $font-size-sm;
      }

      &:hover {
        color: $info;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      // wrap li, contenitore per la scrollbar
      &-wrap {
        @extend .booking-engine--panel;
        overflow: auto;
        max-height: 250px;

        // solo per firefox
        scrollbar-width: thin;
        scrollbar-color: $gray-500 transparent;
        //

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $gray-600;
          border-radius: 2.5px;
        }
      }

      // ul
      &-list {
        max-width: calc(100% - #{$gutter});
        padding: 0;
        list-style: none;
        li {
          list-style: none;
          &:last-child {
            margin-bottom: $gutter/2;
          }
        }
      }
    }
  }

  &--dates {
    position: relative;
    .vc-wrapper {
      @extend .booking-engine--panel;
      padding-left: $gutter/2;
      padding-right: $gutter/2;
      .picker-alert {
        text-align: center;
        font-size: $font-size-md;
        .alert {
          margin-bottom: 0;
          padding: 0.35rem 0.5rem;
        }
      }
    }
    .vc-container {
      //@extend .booking-engine--panel;
      //padding-left: $gutter/2;
      //padding-right: $gutter/2;
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .vc-weeks {
      padding: 0;
      margin-top: $gutter/2;
    }
    .vc-arrows-container,
    .vc-header {
      padding: 0;
      margin:0;
    }

    .vc-container {
      --blue-600: #{$primary};
      --text-xs: 12px;
      --text-sm: 13px;
      --text-base: 14px;
      --font-family: #{$font-family-base};
      @include media-breakpoint-between(md, lg) {
        min-width: 280px;
      }      
    }
  }

  &--occupancy {
    position: relative;
    .occupancy {
      @extend .booking-engine--panel;
      @include media-breakpoint-down(smd) {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }
    } 
  }

  .occupancy--slot--footer {
    margin-top: $gutter;
    .vb-btn {
      display: block;
      width: 100%;
    }
  }

  .popover {
    * {
      border:0px;
      box-shadow: none;
    }
  }

  @include media-breakpoint-down(sm) {
    &--autocomplete-result-list {
      position: static !important;
      max-width: 100%;
      margin-top: 0;
      li {
        padding: $grid-gutter-width/6 $grid-gutter-width/2;
        .booking-engine--autocomplete-result-label {
          padding-left: 24px;
          line-height: 100%;
        }
      }
    }
    &--dates {
      .vc-container {
        position: static;
        margin-top: 0;
      }
    }
    &--occupancy .occupancy {
      position: static;
      margin-top: 0;
    }
  }
}
</style>