<template>
  <b-col md="12" xl="6">
    <section class="codes-refer">
      <p class="codes-refer__title" v-html="'Codici e Riferimenti'" />
      <div class="codes-refer__info">
        <p v-html="'N°prenotazione Booking.com: <strong class=\'semibold\'>' + confirm.bookingVoucher.reservation_id + '</strong>'" />
        <p v-html="'Codice di conferma: <strong class=\'semibold\'>' + confirm.bookingVoucher.confirmcode + '</strong>'" />
        <p v-html="'Persona di riferimento: <strong class=\'semibold\'>' + confirm.bookingVoucher.displayName + '</strong>'" />
        <p v-html="'Email: <strong class=\'semibold\'>' + confirm.bookingVoucher.email + '</strong>'" />
      </div>
    </section>
  </b-col>
</template>

<script>
import Vue from "vue";

export default {
  name: 'codes-references',

  components: {
  },

  props: {
    confirm: Object
  },

  data() {
    return {
      config: Vue.prototype.$config,
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

.codes-refer {
  background-color: $white;
  border-radius: 10px;
  padding: 15px 20px;
  border: 1px solid $gray-300;
  height: 100%;

  @media print {
    border: none;
    padding: 0;
    background-color: $white;
    margin-bottom: $grid-gutter-width*2;
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $vb-font-weight-semibold;
    margin-bottom: 10px;
  }

  &__info {
    p {
      margin-bottom: 5px;
    }
  }
}

</style>