<template>
  <section :id="$options.name" class="facilities" ref="facilities" >
    <div v-for="(hotel, hotel_index) in hotels" :key="_uid+'facilities'+hotel_index">
      <h2 class="facilities__title" v-html="hotel.hotel_data.name + ': Servizi'" />
      <p class="facilities__subtitle" v-html="'Alcuni servizi potrebbero essere a pagamento.'" />

      <ul class="facilities__list" :class="show_facilities && 'facilities__list--open'">
        <li class="facilities__list-item"
            v-for="(facility, facility_index) in hotel.hotel_data.hotel_facilities"
            :key="_uid + 'facility' + facility_index"
        >
          <vb-icon class="facilities__list-icon" :name="'check_circle'" :color="'f1ae04'" :size="14" />
          <span class="facilities__list-text" v-html="facility.name" />
        </li>
      </ul>

      <span v-if="is_mobile()" class="facilities__show" v-b-modal="'modal_facilities_' + hotel_index" v-html="'vedi i servizi'" />
      <span v-else-if="hotel.hotel_data.hotel_facilities.length > 15" class="facilities__show" v-html="!show_facilities ? 'vedi tutti i servizi' : 'chiudi'" @click="toggle_facilities()" />

      <vb-modal
          :vb_options="{
                  id: 'modal_facilities_' + hotel_index,
                  body_class: 'facilities__modal',
                  title: hotel.hotel_data.name + ': Servizi',
                  size: 'lg',
                  centered: true,
                  hide_footer: true
                }"
      >
        <template v-for="(facility, facility_index) in hotel.hotel_data.hotel_facilities">
          <span class="facilities__modal-item" :key="_uid + 'facility' + facility_index" v-html="facility.name" />
        </template>
      </vb-modal>

      <div class="facilities__languages">
        <h3 class="facilities__languages-title">
          <vb-icon class="facilities__languages-icon" :name="'chat'" :color="sass('primary')" :size="20" />
          <span v-html="'Lingue parlate:'" />
        </h3>
        <span class="facilities__languages-text"
              v-for="(lang, lang_index) in hotel.hotel_data.spoken_languages"
              :key="_uid + 'lang' + lang_index"
              v-html="get_languages(lang).label"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {get_languages} from "@booking/resources";

export default {
  name: 'facilities',

  components: {
  },

  props: {
    hotels: Array,
  },

  data() {
    return {
      show_facilities: false
    }
  },

  computed: {
  },

  created() {
    this.get_languages = get_languages
  },

  mounted() {
  },

  methods: {
    toggle_facilities() {
      this.show_facilities = !this.show_facilities

      if(!this.show_facilities) {
        const facilities = this.$refs['facilities']

        if (facilities) facilities.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

  .facilities {
    @extend %section;
    @extend %section_margin;

    &__title {
      @extend %section__title;
      margin-bottom: $grid-gutter-width/2;
    }

    &__subtitle {
      font-size: $font-size-md;


      @include media-breakpoint-up(lg){
        margin-bottom: $grid-gutter-width;
      }
    }

    &__list {
      display: none;

      @include media-breakpoint-up(xl){
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        height: 245px;
        overflow: hidden;

        &--open {
          height: auto;
        }

        &-item {
          list-style: none;
          line-height: normal;
          padding: 5px 30px 5px 0;
          margin: 3px 5px 3px 0;
          width: 32%;
          display: flex;
          align-items: center;
        }

        &-icon {
          margin-top: 2px;
          margin-right: 10px;
        }
      }
    }

    &__modal {

      &-item {
        font-size: $font-size-md;
        margin-bottom: 8px;
        display: block;
        line-height: normal;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__show {
      display: block;
      color: $info;
      text-decoration: underline;
      font-size: $font-size-md;
      cursor: pointer;
      justify-content: flex-end;

      @include media-breakpoint-up(lg){
        margin-top: 15px;
      }
    }

    &__languages {
      margin-top: $grid-gutter-width;

      @include media-breakpoint-up(md){
        display: flex;
        align-items: center;
        margin-top: $grid-gutter-width*2;
      }

      &-icon {
        margin-right: 5px;
      }

      &-title {
        display: flex;
        align-items: center;
        margin-right: $grid-gutter-width/2;

        @include media-breakpoint-up(md){
          margin-bottom: 0;
        }
      }

      &-text {
        margin-right: 10px;
        margin-top: 3px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

</style>