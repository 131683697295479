<template>
  <b-col md="12">
    <section class="conditions">
      <p class="conditions__title" v-html="'Condizioni per i bambini e letti supplementari'" />
      <div class="conditions__wrap">
        <div class="conditions__item">
          <p class="conditions__item-title" v-html="'<span class=\'semibold\'>Condizioni per culle e letti aggiuntivi</span>'" />
          <ul class="conditions__item-list mb-0">
            <li class="conditions__item-li" v-html="'Le richieste sono soggette a disponibilità'" />
            <li class="conditions__item-li" v-html="'Le richieste devono essere confermate, <span class=\'semibold\'>contatta la struttura</span>'" />
            <li class="conditions__item-li" v-html="'Le richieste potrebbero comportare costi extra'" />
          </ul>
        </div>
      </div>
    </section>
  </b-col>
</template>

<script>

export default {
  name: 'conditions',

  components: {
  },

  props: {
    confirm: Object
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .conditions {
    margin-top: $grid-gutter-width*2;

    &__title {
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 20px;
    }

    &__wrap {
      padding: 15px 20px;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 10px;
    }

    &__item {
      &_title {
        margin-bottom: 10px;
      }
    }

    @media print {
      display: none;
    }
  }

</style>