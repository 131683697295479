<template>
  <div class="recap">
    <div class="recap__title" v-if="show_matching">
      <p class="mb-0">
        <strong class="semibold text--lg text-primary" v-html="'Assegna ogni gruppo alla sua camera'" /><br>
        <span v-html="'In base al numero di adulti e bambini, associa ogni gruppo alla camera adeguata.'" />
      </p>
      <vb-button
        v-b-modal="'recap_modal'"
        v-html="'Annulla e ricomincia'"
        :vb_options="{
          size: 'sm',
          variant: 'white'
        }"
      />
    </div>
    <b-card no-body class="recap__wrapper">
      <b-card-header v-if="show_header" class="recap__header">
        <div class="recap__header-element recap__header-element--first">
          <div class="recap__header-item">
            <vb-icon class="recap__header-icon mr-1" :name="'calendar'" :color="sass('primary')" :size="16" />
            <span v-html="recap_header.checkin + ' - ' + recap_header.checkout" />
          </div>
          <div class="recap__header-item">
            <vb-icon class="recap__header-icon mr-1" :name="'occupants'" :color="sass('primary')" :size="16" />
            <span class="reservation__info-text semibold" v-html="recap_header.adults" />
            <span class="ml-1" v-if="recap_header.children" v-html="' e ' + recap_header.children" />
          </div>
        </div>
        <div class="recap__header-element recap__header-element--second">
          <div class="recap__header-item recap__header-item--hotel">
            <vb-icon class="recap__header-icon mr-1" :name="'pin'" :color="sass('primary')" :size="12" />
            <span class="semibold" v-html="solution.hotel_name" />
            <span v-html="solution.hotel_address" />
          </div>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="recap__room" v-for="(room, room_index) in this.parsed_rooms" :key="_uid+'recap_room'+room_index">
          <div class="recap__room-head">
            <span class="recap__room-title" v-html="room.name" />
          </div>
          <div class="recap__blocks">
            <div class="recap__block" v-for="(blocks, block_index) in room.blocks" :key="_uid+'recap_room_block'+block_index">
              <div class="recap__block-wrap">
                <div class="recap__block-item">
                  <occupancy-block @badge-occupancy="get_badge_occupancy" :block="blocks.block" :occupancy="solution.checkout_payload.occupancy" />
                  <mealplan-refundable-block :block="blocks.block" />
                </div>
                <div class="recap__block-info">
                  <b-badge variant="light" v-html="badge_occupancy[blocks.block.block_id]" />
                  <b-alert show v-if="Object.keys(blocks.matched).length > 0 && show_matching" :variant="blocks.matched.variant">
                    <vb-icon class="mr-1" :name="blocks.matched.icon" :color="blocks.matched.icon_color" :size="10" />
                    <span v-html="'<strong class=\'semibold\'>Gruppo ' + blocks.matched.occupancy_match.index +':</strong> ' + blocks.matched.text" />
                  </b-alert>
                </div>
              </div>
              <b-dropdown
                v-if="show_matching"
                :no-caret="true"
                :variant="get_allocation(room_index, block_index).allocated ? 'outline-primary' : 'primary'"
                size="sm"
              >
                <template #button-content>
                  <div :set_allocation="allocation = get_allocation(room_index, block_index)">
                    <occupancy-icons v-if="allocation.allocated" :occupancy="{
                        adults: allocation.adults,
                        children: allocation.children,
                      }"
                    />
                    <span v-else v-html="'Scegli il gruppo'" />
                  </div>
                </template>
                <b-dropdown-item
                  @click="check_availability({
                    room_index: room_index,
                    block_index: block_index,
                    occupancy_index: null
                  })"
                >
                  <div class="recap__dropdown-occupancy">
                    <span>Annulla l'assegnazione</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(occupancy, occupancy_index) in solution.checkout_payload.occupancy"
                  :key="_uid+'recap_occupancy'+occupancy_index"
                  @click="check_availability({
                    room_index: room_index,
                    block_index: block_index,
                    occupancy_index: occupancy_index
                  })"
                  :disabled="
                    set_options_occupancy.length > 0 &&
                    set_options_occupancy[room_index][block_index].disabled_index.includes(occupancy_index)
                  "
                >
                  <span class="recap__dropdown-title" v-html="'Gruppo ' + (occupancy_index + 1)" />
                  <div class="recap__dropdown-occupancy">
                    <div class="recap__dropdown-label">
                      <span v-html="'Adulti: ' + '<strong class=\'semibold text-primary\'>' + occupancy.adults + '</strong>'" />
                      <template v-if="occupancy.children.length">
                        <span v-html="'Bambini: ' + '<strong class=\'semibold text-primary\'>' + occupancy.children.length + '</strong>'" />
                        <div class="recap__dropdown-age">
                          <template v-for="(age_child, age_child_index) in occupancy.children">
                            <span :key="_uid+'age_child'+age_child_index" v-html="age_child" />
                          </template>
                          <span v-html="(occupancy.children.length === 1 && occupancy.children[0] === 1)  ? 'anno' : 'anni'" />
                        </div>
                      </template>
                    </div>
                    <occupancy-icons
                      class="recap__dropdown-icons"
                      :occupancy="{
                        adults: occupancy.adults,
                        children: occupancy.children.length,
                      }"
                    />
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="recap__alert" v-if="alert_matched && show_matching">
          <p class="recap__alert-title">
            <vb-icon class="mr-1" :name="'exclamation'" :color="sass('danger')" :size="16" />
            <span v-html="'Almeno una camera non ha spazio sufficiente o è inadatta.'" />
          </p>
          <p class="recap__alert-desc">
            <span v-b-modal="'recap_modal'">Puoi riassegnare le camere</span> oppure
            <router-link :to="{name: 'detail'}" v-html="'puoi modificare le camere che hai scelto.'" /><br>
            <span v-html="'Leggi le <strong class=\'semibold\'>Condizioni per i bambini e i letti supplementari</strong> qui sotto e poi contatta la struttura per verificare possibili eccezioni. <br>Se decidi di procedere senza verificare potrebbero essere richiesti supplementi al momento dell’arrivo in hotel.'" />
          </p>
        </div>

        <b-card-text v-if="show_footer" class="recap__price">
          <span v-html="'include gestione e assistenza'" />
          <span v-html="'€ ' + solution.checkout_payload.live_price" />
        </b-card-text>
      </b-card-body>
    </b-card>

    <vb-modal
        :vb_options="{
          id: 'recap_modal',
          body_class: 'recap__modal',
          title: 'Annulla l\'assegnazione delle camere e ricomincia',
          size: 'md',
          button_size: 'sm',
          ok_title: 'Procedi',
          ok_only: true,
          centered: true,
        }"
        @ok="empty_matched"
    >
      <p v-html="'Se clicchi su Procedi, la precedente allocazione dei gruppi alle camere verrà annullata e potrai farla di nuovo.'" />
    </vb-modal>
  </div>
</template>

<script>
import OccupancyBlock from '@booking/components/blocks/occupancy-block.vue'
import MealplanRefundableBlock from '@booking/components/blocks/mealplan-refundable-block.vue'
import OccupancyIcons from '@booking/components/atoms/occupancy_icons.vue'
import { parse_date } from '@utilities/commons'
import { add, subtract, sum } from 'mathjs'

export default {
  name: 'card-recap',

  components: {
    'occupancy-block': OccupancyBlock,
    'mealplan-refundable-block': MealplanRefundableBlock,
    'occupancy-icons': OccupancyIcons,
  },

  props: {
    solution: Object,
    show_header: {
      type: Boolean,
      default: true
    },
    show_footer: {
      type: Boolean,
      default: true
    },
    show_matching: {
      type: Boolean,
      default: false
    },
    accommodations: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      recap_header: this.get_recap_header(),
      badge_occupancy: {}, // oggetto di stato per i badge della occupancy
      parsed_rooms: this.parse_rooms(),
      set_options_occupancy: [], // abilita/disabilita occupancy tendina dropdown
      recap_accommodations: {...this.accommodations}
    }
  },

  computed: {
    alert_matched() {
      return this.parsed_rooms.some(room =>
        room.blocks.some(block => block.matched.value === false)
      )
    },
  },

  created() {
    this.parse_date = parse_date
  },

  mounted() {

    // allocazione automatica se dalla ricerca arriva una sola camera
    this.get_auto_allocation()

    // svuoto le allocazioni se si ricarica la pagina
    this.get_rooms_accommodations()
  },

  watch: {
    'solution.rooms': {
      handler(rooms) {
        this.parse_rooms(rooms)
      },
      deep: true,
    },
  },

  methods: {

    get_recap_header() {

      const {checkin, checkout} = this.$store.state.livebk,
            occupancy = this.solution.checkout_payload.occupancy,
            adults = occupancy.reduce((total, item) => total + item.adults, 0),
            adults_label = adults > 1 ? 'adulti' : 'adulto',
            children = occupancy.reduce((total, item) => total + item.children.length, 0),
            children_label = children > 1 ? 'bambini' : 'bambino'


      return {
        checkin: parse_date(checkin).day_number + ' ' + parse_date(checkin).month_string + ' ' + parse_date(checkin).year,
        checkout: parse_date(checkout).day_number + ' ' + parse_date(checkout).month_string + ' ' + parse_date(checkout).year,
        adults: `${adults} ${adults_label}`,
        children: children > 0 && `${children} ${children_label}`,
      }
    },

    get_allocation(r_index, b_index) {

      const occupancy = this.solution.checkout_payload.occupancy,
            has_options = Object.keys(this.set_options_occupancy).length > 0,
            selected_index = has_options ? this.set_options_occupancy[r_index][b_index].selected_index : null,
            allocation = has_options && selected_index !== null

      return {
        adults: allocation ? occupancy[selected_index].adults : null,
        children: allocation ? occupancy[selected_index].children.length : null,
        allocated: allocation
      }
    },

    get_badge_occupancy(block_id, tooltip) {
      this.$set(this.badge_occupancy, block_id, tooltip)
    },

    parse_rooms() {
      this.parsed_rooms = []

      this.solution.rooms.forEach(room => {
        let new_blocks = []

        Object.entries(room.blocks).forEach(([block_id, block]) => {
          const quantity = block.incremental_price_selected.quantity

          for (let i = 0; i < quantity; i++) {
            new_blocks.push({
              block_id: block_id,
              block: {
                block_id: block.block_id,
                max_adults: block.max_adults,
                max_children_free: block.max_children_free,
                max_children_free_age: block.max_children_free_age,
                max_occupancy: block.max_occupancy,
                mealplan_description: block.mealplan_description,
                mealplan_parsed: block.mealplan_parsed,
                refundable: block.refundable,
                refundableBefore: block.refundableBefore,
              },
              matched: {},
            })
          }
        })

        this.parsed_rooms.push({
          id_room: room.id_room,
          name: room.name,
          blocks: new_blocks
        })
      })

      return this.parsed_rooms
    },

    set_occupancy_dropdown(block, ids) {

      // inizializza set_options_occupancy solo se non è già stato popolato
      if (!this.set_options_occupancy || this.set_options_occupancy.length === 0) {

        // itera attraverso parsed_rooms e i loro blocchi per popolare set_options_occupancy
        this.parsed_rooms.forEach(room => {

          const room_blocks = []

          room.blocks.forEach(roomBlock => {
            room_blocks.push({
              block_id: roomBlock.block.block_id,
              disabled_index: [],   // indici della occupancy della tendina da disabilitare
              selected_index: null  // valore della occupancy della tendina selezionata
            })
          })

          this.set_options_occupancy.push(room_blocks)
        })
      }

      // trovo la room che contiene il blocco specifico
      const exist_room_index = this.set_options_occupancy.find(room =>
          room.some((b, index) => b.block_id === block.block_id && index === ids.block_index)
      )

      if (exist_room_index) {
        // indice del blocco specifico della room trovata
        const block_index_exist = exist_room_index.findIndex(
            (b,index) => b.block_id === block.block_id && index === ids.block_index
        )

        if (block_index_exist !== -1) {
          // ottengo il vecchio valore di selected_index
          const old_select_index = exist_room_index[block_index_exist].selected_index

          // aggiorno selected_index del blocco trovato con l'indice della occupancy passata
          exist_room_index[block_index_exist].selected_index = ids.occupancy_index

          // rimuovo il vecchio valore di selected_index dagli array disabled_index degli altri blocchi
          this.set_options_occupancy.forEach(room => {
            room.forEach((b,index) => {
              if (b.block_id !== block.block_id || index !== ids.block_index) {
                const old_index = b.disabled_index.indexOf(old_select_index)
                if (old_index !== -1) {
                  b.disabled_index.splice(old_index, 1)
                }
              }
            })
          })

          // se occupancy_index non è null, lo aggiungo agli array disabled_index degli altri blocchi
          if (ids.occupancy_index !== null) {
            this.set_options_occupancy.forEach(room => {
              room.forEach((b, index) => {
                if (b.block_id !== block.block_id || index !== ids.block_index) {
                  b.disabled_index.push(ids.occupancy_index)
                }
              })
            })
          }
        }
      }

      return this.set_options_occupancy
    },

    get_rooms_accommodations() {

      let rooms_accommodation = this.parsed_rooms.flatMap(room => room.blocks),
          ordered_rooms = []

      rooms_accommodation.forEach((room, index) => {
        if (Object.keys(room.matched).length > 0) {
          let result = Array(room.matched.occupancy_match.occupancy.adults).fill('A')
                      .concat(room.matched.occupancy_match.occupancy.children)

          ordered_rooms.push({ room: 'room' + (index + 1), value: result.join(',') })
        } else {
          ordered_rooms.push({ room: 'room' + (index + 1), value: null })
        }
      })

      this.recap_accommodations.rooms = this.recap_accommodations.rooms || {}
      this.recap_accommodations.rooms = ordered_rooms
          .filter(room => room.value !== null)
          .reduce((acc, curr) => {
            acc[curr.room] = curr.value
            return acc
          }, {})

      this.recap_accommodations.multiroom_assignment = rooms_accommodation.every(room =>
        (room.matched && Object.keys(room.matched).length > 0) &&
        room.matched.occupancy_match.occupancy.adults <= room.block.max_adults &&
        rooms_accommodation.length === this.solution.checkout_payload.occupancy.length
      )

      let selected_solution = this.solution || {}
      selected_solution.checkout_payload = selected_solution.checkout_payload || {}
      selected_solution.checkout_payload.accomodations = this.recap_accommodations

      this.$store.commit('livebk/selected_solution', selected_solution)
    },

    check_availability(ids) {

      let occupancy = this.solution.checkout_payload.occupancy[ids.occupancy_index],
          room = this.parsed_rooms[ids.room_index],
          block = room.blocks[ids.block_index].block

      if(ids.occupancy_index !== null) {

        let total_rooms_occupancy = 0,  // totale della occupancy effettiva (che non è la max occupancy) del blocco
            total_children_free = 0,    // bambini gratis del blocco
            rooms_paying = 0,           // totale persone paganti del blocco (adulti)

            total_occupancy = 0,        // totale della occupancy della mia ricerca
            occupancy_paying = 0,        // totale persone paganti della mia ricerca

            {block_id, max_adults, max_children_free, max_children_free_age, max_occupancy} = block,
            is_child_block = !block_id.endsWith("_0"),
            value_matched


        // calcolo la quantità totale della mia occupancy
        total_occupancy += occupancy.adults + occupancy.children.length

        // calcolo la quantità totale di persone paganti della mia occupancy (gli adulti)
        occupancy_paying += occupancy.adults


        // CHECK SUL BLOCCO

        // se la max_adults e il max_children_free superano la capienza massima allora il numero massimo dei bambini gratis
        // va ridotto fino a coprire la max_occupancy
        if (add(max_adults, max_children_free) > max_occupancy) max_children_free = subtract(max_occupancy, max_adults)

        // calcolo la quantità totale di persone che le camere possono ospitare
        total_rooms_occupancy = add(total_rooms_occupancy, sum(max_adults, max_children_free))

        // se max_adults e max_occupancy sono uguali, sottraggo i max_children_free da total_rooms_occupancy, condizione legata all'icona
        // del bambino che non viene mostrata quando max_adults e max_occupancy sono uguali
        if (max_adults === max_occupancy && max_children_free > 0) total_rooms_occupancy = subtract(total_rooms_occupancy, max_children_free)

        // se il total_rooms_occupancy (max_adults + max_children_free) e maggiore della occupancy, riduco il total_children_free
        if (total_rooms_occupancy > max_occupancy) total_children_free = subtract(max_occupancy, max_adults)

        // calcolo la quantità totale di bambini gratis che le camere possono ospitare
        total_children_free = add(total_children_free, max_children_free)

        // calcolo la quantità totale di persone paganti (gli adulti)
        rooms_paying = add(rooms_paying, max_adults)

        // se è un child_block (cioè un bambino paga una tariffa agevolata) incremento della room il pagante e il totale
        if (is_child_block && max_children_free > 0 && max_children_free_age >= 0) {
          rooms_paying = add(rooms_paying, 1)
          if (total_rooms_occupancy < max_occupancy) {
            total_rooms_occupancy = add(total_rooms_occupancy, 1)
          }
        }


        // CHECK SULLA OCCUPANCY DELLA RICERCA

        // per ogni bambino presente in occupancy, cerco una corrispondenza in un blocco basata sulla disponibilità (total_children_free)
        // se non trovo una corrispondenza, viene incrementato il contatore occupancy_paying
        for (const current_child_age of occupancy.children) {

          let match_found = false

          if ((total_children_free > 0) && (current_child_age <= block.max_children_free_age)) {
            match_found = true
            total_children_free = subtract(total_children_free, 1)
            break
          }

          if (match_found) {
            break
          }

          if (!match_found) {
            occupancy_paying = add(occupancy_paying, 1)
          }
        }


        // AGGIORNAMENTO DI PARSED ROOMS IN BASE AL MATCHING

        value_matched = (total_rooms_occupancy >= total_occupancy) && (rooms_paying >= occupancy_paying)

        this.parsed_rooms[ids.room_index].blocks[ids.block_index].matched = {
          value: value_matched,
          variant: value_matched ? 'success' : (!value_matched && occupancy.adults <= max_adults) ? 'warning' : 'danger',
          text: value_matched ? 'OK!' : (!value_matched && occupancy.adults <= max_adults) ? 'spazio nella camera insufficiente' : 'camera non adatta',
          icon: value_matched ? 'check_circle' : 'exclamation',
          icon_color: value_matched ? '0c5028' : (!value_matched && occupancy.adults <= max_adults) ? '90881e' : '7b151c',

          occupancy_match: {
            index: ids.occupancy_index + 1,
            occupancy: occupancy
          },
        }
      } else {
        this.parsed_rooms[ids.room_index].blocks[ids.block_index].matched = {}
      }

      this.set_occupancy_dropdown(block, ids)
      this.get_rooms_accommodations()
    },

    empty_matched() {
      this.parsed_rooms.forEach(room => {
        room.blocks.forEach(block => {
          block.matched = {}
        })
      })
      this.set_options_occupancy = []
      this.get_rooms_accommodations()
    },

    get_auto_allocation() {

      const {blocks, occupancy} = this.solution.checkout_payload,
            rooms = Object.values(blocks).reduce((total, value) => total + value, 0)

      if(
          this.parsed_rooms.length === 1 &&
          this.parsed_rooms[0].blocks.length === 1 &&
          rooms === occupancy.length
      ) {

        this.check_availability({
          room_index: 0,
          block_index: 0,
          occupancy_index: 0
        })
      }
    }

  },
}
</script>

<style lang="scss" scoped>

  .recap {

    &__title {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }

      .btn {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }

        &-white {
          background-color: $white;
        }
      }
    }

    &__wrapper {
      border-radius: 10px;
      border: none;
    }

    &__header {
      $this_recap_header: &;
      background-color: #fafcff;
      border-bottom: 1px solid $gray-300;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &-element {

        &--first {
          display: flex;
          align-items: center;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        margin-left: 15px;

        &:first-child {
          margin-left: 0;
        }

        @at-root #{$this_recap_header}-element--first & {
          font-weight: $vb-font-weight-semibold;
        }

        &--hotel {
          font-size: $font-size-md;

          span {
            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }

    &__room {
      border-top: 1px solid $gray-300;
      margin-top: 10px;
      padding-top: 10px;

      &:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
      }

      &-head {
        display: flex;
        align-items: center;
        font-weight: $vb-font-weight-semibold;
      }
    }

    &__blocks {
      margin-left: 10px;
      margin-top: 10px;
    }

    &__block {
      border-top: 1px solid $gray-200;
      margin-top: 10px;
      padding-top: 10px;
      text-align: center;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
      }

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }

      &-item {
        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      &-info {
        text-align: left;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .badge {
          font-weight: normal;
          font-size: $font-size-md;
          padding: 5px;
          margin-right: 10px;
          border: 1px solid transparent;
          margin-bottom: 5px;

          @include media-breakpoint-up(sm) {
            margin-bottom: 0;
          }
        }

        .alert {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          padding: 5px;
          font-size: $font-size-md;
          line-height: 1;


          @include media-breakpoint-up(sm) {
            margin-bottom: 0;
          }

          &-danger {

            span {
              color: #7b151c;
            }
          }
        }
      }
    }

    &__dropdown {

      &-title {
        color: $gray-600;
        font-size: $font-size-sm;
        display: block;
      }

      &-occupancy {
        font-size: $font-size-md;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-label {
        display: flex;
        align-items: center;

        & > span {
          &:first-child {
            margin-right: 10px;
          }
        }
      }

      &-age {
        margin-left: 3px;

        &:before, &:after {
          content: "(";
          display: inline-block;
        }
        &:after {
          content: ")";
        }

        span {
          $this: &;

          &:first-child {
            &:before {
              content: ''
            }
          }

          &:last-child {
            &:before {
              content: '';
              margin-left: 2px;
            }
          }

          &:before {
            content: ',';
            display: inline-block;
          }
        }
      }

      &-icons {
        margin-left: $grid-gutter-width*2;
      }
    }

    &__alert {
      color: $danger;
      margin-top: $grid-gutter-width;
      padding-top: 20px;
      border-top: 2px solid $vb-body-bg;

      &-title {
        display: flex;
        align-items: center;
        font-weight: $vb-font-weight-semibold;
        margin-bottom: 0;
      }

      &-desc {
        margin-bottom: 0;
        color: $vb-body-color;

        span:first-child, a {
          color: $info;
          text-decoration: underline;
        }
      }
    }

    &__price {
      border-top: 1px solid $gray-200;
      padding-top: 10px;
      margin-top: 10px;

      span {
        &:first-child {
          color: $gray-500;
          font-size: $font-size-md;
          margin-right: 10px;
        }
        &:last-child {
          color: $primary;
          font-size: $font-size-lg;
          font-weight: $vb-font-weight-semibold;
        }
      }
    }
  }

  ::v-deep {

    .occupancy-block {
      margin-right: 20px;
    }

    .mealplan-refundable {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 22px;

      &__info {
        margin-bottom: 0;
        margin-right: 10px;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .dropdown {
      $this: &;

      @at-root {
        #{$this}.show .dropdown-toggle,
        #{$this} .dropdown-toggle:hover {
          border-color: $gray-300 !important;
          background-color: $light !important;
          color: $dark!important;
          box-shadow: inset 0 0.1rem 0.3rem rgb(0 0 0 / 15%), 1px 1px 5px #fff, inset -0.625rem -0.625rem 1.25rem rgb(255 255 255 / 50%) !important;
        }

        *[class*="btn-outline-"],
        #{$this}.show .dropdown-toggle,
        #{$this} .dropdown-toggle:hover {
          &:after {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' id='Outline' viewBox='0 0 24 24' width='512' height='512'%3E%3Cpath fill='%23000' d='M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z'/%3E%3C/svg%3E%0A") !important;
          }
        }

        #{$this}.show .dropdown-toggle {
          &:after {
            background-position: center 0;
            transform: rotate(180deg);
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin: 20px 0 10px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        margin-left: -10px;
      }

      &-toggle {
        font-weight: $vb-font-weight-semibold;
        display: flex;
        align-items: center;
        border-width: 2px;
        max-height: 35px;

        // freccia
        &::after {
          width: 20px;
          height: 20px;
          border: none;
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' id='Outline' viewBox='0 0 24 24' width='512' height='512'%3E%3Cpath fill='%23fff' d='M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center 1px;
          background-size: cover;
          margin-left: 5px;
        }

        @include media-breakpoint-down(sm) {
          margin: 0 auto;
        }

        .occupancy-icons {
          background-color: transparent;
        }
      }

      &-menu {
        border-radius: 10px;
        border: 1px solid $gray-200 !important;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
        left: auto !important;
        right: 0;
        padding: 0;
        overflow: hidden;
        transform: none !important;
        top: 35px !important;
        outline: 0;

        @include media-breakpoint-down(sm) {
          position: static !important;
          float: none;
          margin-top: 10px;
        }

        li {
          position: relative;

          &:after {
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            left: $grid-gutter-width/2;
            right: $grid-gutter-width/2;
            background-color: $gray-200;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }

      &-item {
        padding: 10px $grid-gutter-width/2;
        color: inherit;

        &:hover {
          background-color: #fafcff;
        }

        &.disabled {
          color: inherit;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $white;
            opacity: .75;
          }
        }
      }
    }
  }

</style>