<template>
  <div>
    <div class="credit-alert">
      <component
          :is="tag"
          :href="tag === 'a' ? 'javascript:window.zE.activate()' : null"
          :title="title"
          :class="'text-'+color"
          v-b-popover.hover.top="tooltip"
          class="credit-alert__item"
      >
        <span v-html="text" class="credit-alert__text" />
        <vb-icon
            v-if="icon"
            class="credit-alert__icon ml-1"
            :name="'vb-chat'"
            :color="sass('info')"
            :size="18"
        />
      </component>
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'credit-alert',

  components: {
  },

  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Credito non sufficiente',
    },
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
      validator: (value) => ['a', 'div'].includes(value),
    },
    color: {
      type: String,
      default: 'secondary',
    }
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .credit-alert {
    display: flex;
    align-items: baseline;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-sm;
    }

    &__item {
      font-weight: $vb-font-weight-semibold;
    }
  }

</style>