<template>
  <section :id="$options.name" class="description">
    <div v-for="(hotel, hotel_index) in hotels" :key="_uid+'_description_'+hotel_index">
      <vb-icon
          :name="'quote'"
          :size=35
          :color="sass('secondary')"
          class="description__icon"
      />
      <p class="description__text" v-html="hotel.hotel_data.hotel_description" />
      <vb-icon
          :name="'quote'"
          :size=35
          :color="sass('secondary')"
          class="description__icon"
      />
    </div>
  </section>
</template>

<script>


export default {
  name: 'description',

  props: {
    hotels: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

  .description {
    @extend %section;
    background-color: $white;
    padding: $grid-gutter-width/2;
    border-radius: 10px;
    position: relative;

    @include media-breakpoint-up(sm){
      padding: $grid-gutter-width;
    }

    &__icon {
      position: absolute;
      filter: drop-shadow(2px 2px 0px $white);
      display: none;

      @include media-breakpoint-up(sm){
        display: block;
      }

      &:first-child {
        top: -5px;
        left: -15px;
        transform: rotate(-200deg);
      }

      &:last-child {
        bottom: -15px;
        right: -15px;
        transform: rotate(20deg);
      }
    }

    &__text {
      margin-bottom: 0;
    }
  }

</style>