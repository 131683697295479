<template>
  <div class="mealplan-refundable">
    <div class="mealplan-refundable__info">
      <vb-icon class="mealplan-refundable__icon" :name="'breakfast'" :color="sass('primary')" :size="16" />
      <span class="mealplan-refundable__name" v-html="block.mealplan_description" />
    </div>

    <div class="mealplan-refundable__info text-success" v-if="count_true_value()">
      <vb-icon v-if="count_true_value() > 1" class="mealplan-refundable__icon" :name="'cutlery'" :color="sass('success')" :size="16" />
      <vb-icon v-else class="mealplan-refundable__icon" :name="'breakfast'" :color="sass('success')" :size="16" />

      <div class="mealplan-refundable__list">
        <template v-for="(value, key) in block.mealplan_parsed">
          <div class="mealplan-refundable__name"
               v-if="value.included" :key="_uid + '_mealplan-refundable_' + key"
          >
            <span v-html="get_mealplan(key).name" />
            <vb-icon class="mealplan-refundable__icon mealplan-refundable__icon--info" v-if="get_mealplan(key).tooltip !== ''" :name="'info'" :color="sass('gray-500')" :size="14" v-b-popover.hover.top="get_mealplan(key).tooltip" />
          </div>
        </template>
      </div>
      <span class="ml-1" v-html="count_true_value() > 1 ? 'incluse' : 'inclusa'" />
    </div>


    <div v-if="block['refundable']" class="mealplan-refundable__info text-success">
      <vb-icon class="mealplan-refundable__icon" :name="refundable.icon" :color="sass('success')" :size="16" />
      <span class="mealplan-refundable__name" v-html="refundable.name" />
    </div>
    <div v-else class="mealplan-refundable__info">
      <vb-icon class="mealplan-refundable__icon" :name="'lock_v2'" :color="'000'" :size="16" />
      <span class="mealplan-refundable__name" v-html="'Non rimborsabile'" />
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { get_mealplan } from "@booking/resources"

export default {
  name: 'mealplan-refundable-block',

  components: {
  },

  props: {
    block: Object,
  },

  data() {
    return {
      refundable: {
        name: "Cancellazione gratuita fino al " + moment(this.block.refundableBefore, 'DD-MM-YYYY HH:mm:ss Z').format('DD-MM-YYYY'),
        icon: "check-circle",
        tooltip: "",
      }
    }
  },

  computed: {
  },

  created() {
    this.get_mealplan = get_mealplan
  },

  mounted() {
  },

  methods: {
    count_true_value() {
      return Object.values(this.block.mealplan_parsed).filter(value => value.included).length
    }
  },
}
</script>

<style lang="scss" scoped>

  .mealplan-refundable {
    $this: &;
    line-height: normal;

    &__info {
      display: flex;
      align-items: flex-start;
      font-size: $font-size-md;
      margin-bottom: 5px;

      @include media-breakpoint-up(sm){
        margin-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
    }

    &__name {

      @at-root #{$this}__list & {

        &:before {
          content: ",";
          margin-right: 2px;
        }

        &:first-child {
          &:first-letter {
            text-transform: capitalize;
          }

          &:before {
            display: none;
          }
        }

        &:last-child {
          &:before {
            content: "e";
            margin-right: 2px;
            margin-left: 2px;
          }
        }
      }

    }

    &__icon {
      display: none;

      @include media-breakpoint-up(sm){
        display: block;
        margin-right: 6px;
      }

      &--info {
        margin: 2px 0 0 5px;
      }
    }
  }

</style>