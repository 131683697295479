export default '<svg width="101" height="90" viewBox="0 0 101 90" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M55.3569 89.2194L0.271185 2.62869C-0.45306 1.49886 0.357726 0.00683594 1.70482 0.00683594H25.6487C32.8477 0.00683594 39.5394 3.68597 43.4069 9.75514L75.5346 60.2496C75.8967 60.8145 75.8967 61.5243 75.5346 62.0747L58.2397 89.2049C57.5589 90.2623 56.0232 90.2623 55.3569 89.2194Z" fill="url(#paint0_linear_0_1)"/>\
    <path d="M81.1254 0.035997C72.2462 0.600908 64.9891 7.77088 64.3518 16.6501C63.6131 26.9634 71.58 35.582 81.6325 35.9441L81.7195 35.9586C82.5886 35.9586 83.284 36.6685 83.284 37.5231V44.4759C83.284 45.2146 83.7614 45.8952 84.4856 46.0545C85.0795 46.1849 85.63 45.982 85.9921 45.6054L97.4068 27.6588C99.2029 24.8632 100.246 21.5318 100.246 17.9685C100.275 7.66972 91.5835 -0.615823 81.1254 0.035997ZM83.4431 22.0675C78.808 22.8207 74.8682 18.8809 75.6214 14.2458C76.0849 11.4357 78.3443 9.17595 81.1544 8.71243C85.7896 7.95922 89.7293 11.8993 88.9761 16.5345C88.5126 19.3446 86.2387 21.6039 83.4431 22.0675Z" fill="#fbae48"/>\
    <path d="M82.2697 36.0311C82.2262 36.0311 82.183 36.0311 82.1395 36.0311C82.8203 36.2049 83.3129 36.8134 83.3129 37.5521V44.5046C83.3129 45.2433 83.7903 45.9242 84.5145 46.0835C85.1084 46.2139 85.6589 46.011 86.021 45.6344L97.0445 28.3105C93.7854 32.9747 88.3823 36.0311 82.2697 36.0311Z" fill="url(#paint1_linear_0_1)"/>\
    <defs>\
        <linearGradient id="paint0_linear_0_1" x1="-3.1405" y1="4.82814" x2="74.4364" y2="69.9232" gradientUnits="userSpaceOnUse">\
            <stop stop-color="#0a76bd"/>\
            <stop offset="0.9926" stop-color="#0F588E"/>\
        </linearGradient>\
        <linearGradient id="paint1_linear_0_1" x1="91.6088" y1="37.7111" x2="83.7694" y2="33.185" gradientUnits="userSpaceOnUse">\
            <stop stop-color="#fbae48"/>\
            <stop offset="0.5573" stop-color="#E5A338"/>\
        </linearGradient>\
    </defs>\
</svg>'
