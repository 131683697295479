<template>
  <div class="payments" v-if="payments_show">
    <!--<div class="d-flex align-items-center mb-2">
      <div class="payments--icon" v-html="svg_payments"></div>
      <strong>Riepilogo pagamenti</strong>
    </div>-->
    <h5 class="vb-heading">Riepilogo pagamenti</h5>
    <div v-for="(payment,index) in payments" v-bind:key="index+_uid">
      <div class="payment" v-if="larger(payment.value, 0)">
        <div class="payment--name">
          {{payment.name}}
        </div>
        <div class="payment--value">
          <strong>{{payment.value}} €</strong>
        </div>
      </div>
    </div>

    <div class="payments__nocredit" v-if="larger(live_price, payments_sum)">
      <div class="payments__nocredit__label">
        <span>Credito non sufficiente?</span>
        <vb-icon
            class="vb-pad__nocredit-icon ml-1"
            :name="'vb-chat'"
            :color="sass('info')"
            :size="18"
        />
      </div>
      <p class="payments__nocredit__text">
        Il tuo credito non è sufficiente a coprire il costo del pacchetto viaggio.
        Cerca un altro prodotto o <a href="javascript:window.zE.activate()">contattaci</a> per maggiori informazioni.
      </p>
    </div>

  </div>
</template>

<script>
import { larger, sum } from 'mathjs'

export default {
    name: 'payments',
    components: {
    },
    props: {
      live_price: String,
      payments: {
        type: [],
        default: () => { return [] }
      }
    },
    computed: {
      payments_sum(){
        let tot = this.payments.reduce(function(accumulator, payment) {
          return sum(accumulator, payment.value)
        }, 0)
        return tot
      },
      payments_show() {
        /**
         * - la vecchia implementazione era: "return this.get_availability().is_known"
         * - l'esigenza era evitare di mostrare il blocco vuoto nel caso: get_availability().is_known == false
         * - l'effetto collaterale era che il blocco restava nascosto anche dopo aver inserito (ad esempio) un codice sconto
         * - ora il componente viene mostrato solo se ho almeno un metodo di pagamento con importo > 0
         * - la logica attuale è agnostica rispetto al flag get_availability().is_known
         */
        let show_sidebar_payment = false
        this.payments.forEach(payment => {
          if (payment.value > 0) show_sidebar_payment = true
        })
        return show_sidebar_payment
      }
    },
    created() {
      this.larger = larger
      this.sum = sum
      this.svg_payments = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-bill-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-money-bill-alt fa-w-20"><path fill="currentColor" d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112 0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" class=""></path></svg>'
    },
    mounted() {
    },
    data() {
      return {}
    },
    methods: {
    }
}
</script>

<style lang="scss">
.payments {
  &--icon {
    display:flex;
    align-items: flex-start;
    svg {
      height:16px;
      width:auto;
      margin-right:$grid-gutter-width/4;
      path {
        //fill:$cta;
      }
    }
  }
  .payment {
    display:flex;
    align-items:center;
    line-height: 1;
    margin-bottom:$grid-gutter-width/4;
    &--name {
      flex:1;
      position:relative;
      display:flex;
      &:after {
        content:"";
        flex:1;
        height: inherit;
        border-bottom:1px solid $gray-300;
        margin:0 6px 2px 6px;
      }
    }
  }

  &__nocredit {
    margin-top: $grid-gutter-width/2;

    &__label {
      color: $secondary;
      font-weight: $vb-font-weight-semibold;
      display: flex;
      align-items: baseline;
      margin-bottom: $grid-gutter-width/6;
    }

    &__text {

      @include media-breakpoint-up(xl) {
        font-size: $font-size-sm;
      }
    }
  }
}
</style>