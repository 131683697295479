<template>
  <b-col md="12" xl="6">
    <section class="hotel">
      <div class="hotel__head">
        <div class="hotel__element">
          <div class="hotel__item">
            <!--<span class="hotel__category" v-html="'_Hotel_'" />-->
            <b-form-rating
                v-if="hotel.class > 0" class="hotel__star"
                no-border inline readonly
                size="sm"
                variant="secondary"
                :value="hotel.class"
            />
          </div>
          <div class="hotel__item">
            <vb-icon v-if="hotel.preferred" class="hotel__icon hotel__icon--like" :name="'like'" :color="sass('secondary')" :size="16" />
            <p class="hotel__name" v-html="hotel.name" />
          </div>
          <div class="hotel__item">
            <vb-icon class="hotel__icon hotel__icon--geo mr-1" :name="'pin'" :color="sass('primary')" :size="12" />
            <span>{{ hotel.address | capitalize }}</span>
            <span class="hotel__address" v-html="', ' + hotel.zip + ' ' + hotel.city" />
          </div>
        </div>
        <div class="hotel__element">
          <score class="hotel__rating" :hotel="hotel" />
        </div>
      </div>
      <ul class="hotel__facilities">
        <template v-for="(facility, facility_index) in hotel.hotel_facilities">
          <li class="hotel__facilities-li" v-if="facility_index < 8" :key="_uid + '_facility_' + facility_index">
            <vb-icon class="rooms-modal__facilities-list_icon mr-1" :name="'check_circle'" :color="sass('gray-600')" :size="14" />
            <span v-html="facility.name" />
          </li>
        </template>
      </ul>
    </section>
  </b-col>
</template>

<script>
import Score from "@booking/components/atoms/score.vue";

export default {
  name: 'hotel-info',

  components: {
    'score': Score,
  },

  props: {
    hotel: Object,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .hotel {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    height: 100%;

    &__icon {

      &--like {
        margin-right: 5px;
      }
    }

    &__head {
      display: flex;
    }

    &__element {
      &:first-child {
        flex-basis: 100%;
      }
    }

    &__item {
      display: flex;
      align-items: center;
    }

    &__category {
      color: $gray-600;
      font-size: $font-size-md;
    }

    &__star {
      width: auto;
    }

    &__name {
      font-weight: $vb-font-weight-semibold;
      font-size: $font-size-lg;
      margin-bottom: 0;
    }

    &__facilities {
      border-top: 1px solid $gray-200;
      margin-top: $grid-gutter-width/2;
      margin-bottom: 0;
      padding: $grid-gutter-width/2 0 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      &-li {
        display: flex;
        align-items: center;
        font-size: $font-size-md;
        line-height: normal;
        padding: 2px 15px 2px 0;
      }
    }

    &__rating {
      font-size: $font-size-md;

      ::v-deep {
        .score__number {
          span {
            $size: 30px;
            width: $size;
            height: $size;
          }
        }
      }
    }
  }

</style>