<template>
  <b-col md="12">
    <section class="thankyou">
      <h1 class="thankyou__title">Complimenti!</h1>
      <p class="thankyou__subtitle">La tua prenotazione è confermata.</p>

      <div class="thankyou__info">
        <div class="d-flex align-items-center">
          <vb-icon class="thankyou__info-icon mr-1" :name="'hotel'" :color="sass('info')" :size="18" />
          <h2 class="thankyou__info-title">Potrai presentarti in struttura con questa Conferma.</h2>
        </div>
        <p class="thankyou__info-text">
          <strong>Contatteremo di nuovo la struttura prima del tuo arrivo per assicurarci che sia tutto pronto per accoglierti.</strong>
          <br>
          <span>Abbiamo inviato all'indirizzo <strong>{{confirm.bookingVoucher.email}}</strong> il riepilogo e l'estratto conto della prenotazione.</span>
          <br><br>
          <strong>Non esitare a contattarci per qualsiasi informazione o supporto.</strong>
        </p>
      </div>

      <p class="thankyou__print">
        <vb-icon class="thankyou__info-icon mr-1" :name="'print'" :color="sass('info')" :size="18" />
        <span @click="window.print()">Stampa la conferma da presentare in hotel</span>
      </p>

      <p class="thankyou__ambient">
        <vb-icon class="thankyou__info-icon mr-1" :name="'leaf'" :color="sass('success')" :size="13" />
        <span>Pensa all'ambiente. Stampa solo se necessario, ti ricordiamo che potrai presentare la Conferma anche da un dispositivo mobile.</span>
      </p>
    </section>
  </b-col>
</template>

<script>
import Vue from "vue";

export default {
  name: 'thankyou',

  components: {
  },

  props: {
    confirm: Object
  },

  data() {
    return {
      config: Vue.prototype.$config,
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .thankyou {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*2;
    }

    &__title {
      margin-bottom: 0;
      color: $primary;
    }

    &__subtitle {
      color: $primary;
      font-size: $font-size-xl;
      font-weight: $vb-font-weight-semibold;
    }

    &__info {
      margin-top: 20px;
      margin-bottom: 50px;

      &-title {
        color: $info;
        font-size: $font-size-lg;
        margin-bottom: 0;
      }

      &-text {
        margin-top: 20px;
      }
    }

    &__print {
      color: $info;
      text-decoration: underline;
      font-weight: $vb-font-weight-semibold;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 5px;

      @media print {
        display: none;
      }
    }

    &__ambient {
      display: flex;
      align-items: center;
      color: $success;
      font-size: $font-size-md;
      margin-bottom: 0;

      @media print {
        display: none;
      }
    }
  }

</style>