 <template>
  <div class="result-view">
    <b-container>
      <booking-engine @search="e => search(e)"/>
      <section class="section section--loader" v-if="searching">
        <loader size="large" :show_timing_slot="true" />
      </section>
      <section class="section filters" v-else>
        <!-- TODO: se vogliamo la alert va gestito l'aggiornamento di current_search nel booking engine alla modifica dei vari campi
        <div v-if=//"last_search !== current_search">
          <b-alert show variant="warning">Attenzione, hai cambiato i criteri di ricerca ma non hai aggiornato i risultati. Clicca su Cerca.</b-alert>
          <br><br>
        </div>-->
        <b-row>
          <b-col cols="3" class="filters__sticky-wrapper" :class="{ visible: show_filters }">
            <div class="filters__content-wrapper">
              <!-- filter resume -->
              <div v-if="filtered.results !== undefined" class="d-flex justify-content-between">
                <p v-if="!searching && !appending">
                  <span class="filters__title text-primary">
                    {{ filtered.results.length }} strutture mostrate
                    <div class="d-flex justify-content-between">
                      <span v-if="total_properties" class="text--sm">su {{ total_properties }} totali</span>
                    </div>
                  </span>
                </p>

                <p class="mt-1 text--sm text-info underline cursor-pointer toggle-map text-right">
                  <span v-if="map" v-html="'Mostra lista'" @click="map = !map" />
                  <span v-if="!map" v-html="'Mostra mappa'" @click="map = !map" />
                  <span v-if="searching | appending"><loader /></span>
                  <span v-else-if="offset>-1" class="d-block underline cursor-pointer mt-1" v-html="'Mostra più risultati'" @click="search()" />
                </p>
              </div>

              <div :key="filtering">
                
                <!-- filter availability -->
                <div>
                  <vb-checkbox
                    @change="filter_results()"
                    v-model="filter_availability"
                    :vb_options="{
                      text: 'Mostra in base al credito',
                      switch: true,
                    }"
                  />
                </div>
             
                <div v-if="active_filters().page_filters.length | active_filters().advanced_filters.length">
                    <hr />
                    <p class="d-flex align-items-center justify-content-between">
                      <span class="filters__section__title">Filtri attivi:</span>
                      <span @click="() => {
                        reset_all_filters()
                      }" class="text--sm text-info underline cursor-pointer">cancella filtri</span>
                    </p>
                    <div class="d-flex flex-wrap mt-2">
                      <!-- active page filters -->
                      <b-badge pill variant="primary" class="d-inline-flex mr-1 cursor-pointer mb-1"
                        v-for="(filter, index) in active_filters().page_filters"
                        v-bind:key="_uid+index+'-page-active'"
                        @click="() => {
                          let payload
                          switch (filter.key) {
                            case 'refundable':
                              payload = !filter_refundable
                              break
                            case 'preferred':
                              payload = !filter_preferred
                              break
                            case 'stars':
                            case 'amenities':
                              payload = {
                                ...filter.filter,
                                selected: !filter.filter.selected,
                              }
                              break
                          }
                          filter.callback(payload)
                        }">
                        <div v-html="filter.text" />
                        <div class="ml-2">&#10005;</div>
                      </b-badge>
                      <!--active advanced filters-->
                      <b-badge pill variant="primary" class="d-inline-flex mr-1 cursor-pointer mb-1"
                        v-for="(filter, index) in active_filters().advanced_filters"
                        v-bind:key="_uid+index+'-advanced-active'"
                        @click="() => {
                          filter.callback()
                        }">
                        <div v-html="filter.text" />
                        <div class="ml-2">&#10005;</div>
                      </b-badge>
                    </div>
                </div>

                <!-- filtri avanzati -->
                <div class="filters__panel mt-3">
                  <b-row>
                    <b-col>
                      <div class="filters__hotel-categories">
                        <div class="filters__hotel-categories__item"
                          v-for="(category,index) in Object.keys(hotel_categories)"
                          v-bind:key="_uid+index+'_type'"
                          :class="{ active: advanced_filters.filter_hotel_categories.includes(index+1) }"
                          @click.stop="() => {
                            if(advanced_filters.filter_hotel_categories.includes(index+1)) {
                              advanced_filters.filter_hotel_categories = advanced_filters.filter_hotel_categories.filter(c => { return c!==index+1 })
                            }
                            else advanced_filters.filter_hotel_categories.push(index+1)
                            submit_advanced_filters()
                          }">
                            <vb-icon
                              class="filters__hotel-categories__icon"
                              :name="hotel_categories[category].icon"
                              :size="16"
                              :color="sass('primary')"
                            />
                            <div class="filters__hotel-categories__name">{{ hotel_categories[category].name }}</div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <div class="filters__panel mt-3">
                  <b-row>
                    <b-col>
                      <p>
                        <span class="filters__section__title">Valutazione dei viaggiatori</span>
                      </p>
                      <div class="filters__review-score" :key="advanced_filters.filter_review_score">
                        <div class="filters__review-score__item" v-for="(score,index) in review_score_values" v-bind:key="_uid+'score'+index">
                          <vb-checkbox
                            @change="value => {
                              update_filter_review_score(score, value)
                            }"
                              :vb_options="{
                                type: 'light',
                                text: score.label,
                                value: score.value == advanced_filters.filter_review_score,
                              }">
                          </vb-checkbox>
                        </div>
                      </div>
                    </b-col>
                  </b-row>

                </div>

                <!-- filtri classici -->
                <div class="filters__panel mt-3">

                  <!-- refundable -->
                  <div v-if="filtered.refundable" :set-total="total = filtered.refundable.length">
                    <vb-checkbox
                      v-if="total"
                      @change="update_filter_refundable"
                      v-model="filter_refundable"
                      :vb_options="{
                        text: 'Solo rimborsabili (' + total + ')',
                        value: filter_refundable,
                        type: 'light',
                      }"/>
                  </div>

                  <!-- preferred -->
                  <div v-if="filtered.preferred" :set-total="total = filtered.preferred.length">
                    <vb-checkbox
                      v-if="total"
                      @change="update_filter_preferred"
                      v-model="filter_preferred"
                      :vb_options="{
                        text: 'Preferiti da Booking.com (' + total + ')',
                        value: filter_preferred,
                        type: 'light',
                      }"/>
                  </div>

                  <!-- stars -->
                  <div v-if="filter_stars.length">
                    <hr />
                    <p>
                      <span class="filters__section__title">Stelle della struttura</span>
                    </p>
                    <vb-checkbox
                      v-for="(option,option_index) in filter_stars" v-bind:key="_uid+'stars'+option_index"
                      @change="(selected) => {
                        update_filter_stars({
                          ...option,
                          selected: selected,
                        })
                      }"
                      v-model="option.selected"
                      :vb_options="{
                        text: option.text + ' ('+option.amount+')',
                        value: option.selected,
                        type: 'light',
                      }"/>
                  </div>

                  <!-- amenities -->
                  <div v-if="filter_amenities.length">
                    <hr />
                    <p>
                      <span class="filters__section__title d-flex align-items-center">
                        Servizi
                        <vb-icon
                          :name="'info'"
                          :color="sass('gray-500')"
                          :size="16"
                          class="ml-1 cursor-pointer"
                          v-b-popover.hover.top="'I filtri indicati non rappresentano una valutazione da parte nostra sulla completezza o qualità dei servizi, in particolare per le caratteristiche di accessibilità per disabili, i servizi per bambini ecc.'"
                        />
                      </span>
                      <span class="filters__section__subtitle">
                        Alcuni servizi possono prevedere costi aggiuntivi 
                        da pagare in loco.
                      </span>
                    </p>
                    <vb-checkbox
                      v-for="(option,option_index) in filter_amenities" v-bind:key="_uid+'amenities'+option_index"
                      @change="(selected) => {
                        update_filter_amenities({
                          ...option,
                          selected: selected,
                        })
                      }"
                      v-model="option.selected"
                      :vb_options="{
                        text: option.text + ' ('+option.amount+')',
                        value: option.selected,
                        type: 'light',
                      }"/>

                      <!--<hr />-->
                  </div>

                </div>
                
              </div>
            </div>
          </b-col>
          
          <b-col cols="7" v-if="filtering">
            <loader  />
          </b-col>

          <b-col xs="12" xl="9" v-if="!filtering">

            <div class="filters__btn-wrapper" v-if="!searching && !appending">
              <!-- title -->
              <div>
                <h3 class="filters__btn-title vb-heading vb-heading--h3" v-html="location.label" />
                <div class="filters__btn-resume">
                  <span class="text-primary" v-if="filtered.results">
                    <strong>{{ filtered.results.length }} strutture mostrate</strong> <span v-if="total_properties">su {{ total_properties }} totali</span>
                  </span>
                </div>                
              </div>
              <!-- buttons -->
              <div class="filters__btn-content">
                <vb-dropdown
                  class="filters__btn-order mr-2 variant-white"
                  :vb_options="{
                    text: sort_values.filter(s => { return s.key == sort_by })[0].label,
                    size: 'sm',
                  }">

                  <b-dropdown-item v-for="(s,index) in sort_values.filter(s => { return s.sort })" v-bind:key="_uid+'sort'+index" :active="sort_by == s.key">
                    <div v-html="s.label"
                      @click="() => {
                        $store.commit('livebk/sort_by', s.key)
                        filter_results()
                      }"
                    />
                  </b-dropdown-item>

                </vb-dropdown>
              </div>
            </div>

            <!--importante v-show per non rigenerare la mappa ogni volta-->
            <div class="results__map" v-show="map">
              <results-google-map
                :map_markers="markers"
                :map_location="location"
                :map_info_window="{
                  offset: {x: 0, y: -40,}
                }"
                :map_style="{
                  styles: [/*snazzy maps json here */],
                }"
                @item-clicked="i => {
                  this.$store.commit('livebk/selected_hotel', i.item)
                  this.$refs[i.item.hotel_id][0].navigate({})
                }"
              />
              <div v-for="res in filtered.results" v-bind:key="res.hotel_id" v-show="false">
                <custom-link
                  :ref="res.hotel_id"
                  :route_name="'detail'"
                  :css_class="'d-none'"
                  :as_default="get_config('guiSettings.openDetailNewTab', false)">
                  <template #content>
                    {{ res.hotel_id }}
                  </template>
                </custom-link>
              </div>
            </div>
            
            <div class="results__list" v-if="!map">
              <results-content-block :hotels="filtered.results" :searching="searching" :appending="appending" />
              <div v-if="appending">
                <loader />
              </div>
              <div v-else-if="offset>-1" class="text-center">
                <vb-button
                  v-if="!searching"
                  @click="search()"
                  :vb_options="{
                    text: 'Mostra più risultati',
                  }"/>  
              </div>
            </div>

          </b-col>
        </b-row>
      </section>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { number, smallerEq, max, min, subtract, floor } from 'mathjs'
import ResultsContentBlock from '@booking/components/views/content-blocks/results'
import GoogleMap from '@components/blocks/map'
import { service_handler } from '@services'
import { encode_parameters, decode_parameters } from '@utilities'
import { get_amenities, hotel_categories, review_score_values } from '@booking/resources'
import { map_markers, markup } from '@booking/utilities'

/*
FILTRI
  - page filters: agiscono sui risultati di ricerca già presenti (persistono sia nello state sia nel localstorage)
  - advanced filters: a livello di UI sono dei filtri, ma a livello logico triggerano nuove ricerche
RICERCA
  - se la ricerca avviene dal booking engine (a prescindere dalla vista in cui mi trovo) questo comporta un reset di tutti i filtri (page + advanced)
  - se la ricerca avviene tramite la modifica di un filtro avanzato (possibile solo dalla modal "Filtri avanzati" in /results) allora i filtri in page vengono preservati
  NB: il reset dei filtri viene gestito tramite un payload (opzionale) che viene aggiungo all'evento search, es:
   {
    reset_page_filters: true,
    discard_advanced_filters: true,
  }
*/
export default {
  name: 'results-view',

  components: {
    'results-content-block': ResultsContentBlock,
    'results-google-map': GoogleMap,
  },

  props: {
  },

  computed: { 
    ...mapState([
      'searching',
      //'availability',
      'occupancy',
    ]),    
    ...mapState(
      'livebk', [
        //'location',
        'checkin',
        'checkout',
        'last_search',
        'current_search',
        'trigger_search',
        'hotels',
        'sort_by',
      ]
    ),
    // v-model filters (mapState non consente l'uso del setter)
    location() {
      return decode_parameters(this.last_search).suggestion
    },
    filter_availability: {
      get() { return this.$store.state.livebk.filter_availability },
      set(value) { this.$store.commit('livebk/filter_availability', value)},
    },    
    filter_refundable: {
      get() { return this.$store.state.livebk.filter_refundable },
      set(value) { this.$store.commit('livebk/filter_refundable', value)},
    },    
    filter_stars: {
      get() { return this.$store.state.livebk.filter_stars },
      set(value) { this.$store.commit('livebk/filter_stars', value)},
    },
    filter_amenities: {
      get() { return this.$store.state.livebk.filter_amenities },
      set(value) { this.$store.commit('livebk/filter_amenities', value)},
    },
    filter_hotel_categories: {
      get() { return this.$store.state.livebk.filter_hotel_categories },
      set(value) { this.$store.commit('livebk/filter_hotel_categories', value)},
    },
    filter_price_range: {
      get() { return this.$store.state.livebk.filter_price_range },
      set(value) { this.$store.commit('livebk/filter_price_range', value)},
    },
    filter_review_score: {
      get() { return this.$store.state.livebk.filter_review_score },
      set(value) { this.$store.commit('livebk/filter_review_score', value)},
    },
    filter_preferred: {
      get() { return this.$store.state.livebk.filter_preferred },
      set(value) { this.$store.commit('livebk/filter_preferred', value)},
    },
    min_price_bound() {
      if (!this.filtered.results) return 0
      if (this.filtered.results.length == 0) return 0
      let h = [ ...this.filtered.results ].sort((a,b) => subtract(a.live_price, b.live_price))
      return floor(number(h[0].live_price))
    },
    max_price_bound() {
      if (!this.filtered.results) return 0
      if (this.filtered.results.length == 0) return 0
      let h = [ ...this.filtered.results ].sort((a,b) => subtract(b.live_price, a.live_price))
      return floor(number(h[0].live_price))
    },
    markers() {
      return map_markers(
        this.filtered.results,
        this.get_availability().total,
        this.sass
      )
    }
  },
  
  data() {
    return {
      show_filters: false,
      updating_price_range: false,
      focus_min_price: false,
      focus_max_price: false,
      total_properties: 0,
      offset: 0,
      filtering: false,
      filtered: {},
      advanced_filters: {},
      sort_values: [
        {
          label: 'Prezzo (dal più basso)',
          key: 'price-asc',
          sort: (a, b) => {
            return number(a.live_price) - number(b.live_price)
          },
        },
        {
          label: 'Prezzo (dal più alto)',
          key: 'price-desc',
          sort: (a, b) => {
            return number(b.live_price) - number(a.live_price)
          },
        },
        {
          label: 'Stelle',
          key: 'stars',
          sort: (a, b) => {
            return number(b.stars) - number(a.stars)
          }
        },
        {
          label: 'Valutazione',
          key: 'salabamScore',
          sort: (a, b) => {
            return number(b.salabamScore) - number(a.salabamScore)
          }          
        },
        {
          label: 'Ordina per',
          key: '',
          sort: false,     
        },
      ],
      review_score_values: review_score_values,
      map: false,
      appending: false,
    }
  },

  created() {
    this.hotel_categories = hotel_categories
    this.discard_advanced_filters()
  },

  mounted() {

    // voglio lanciare una nuova ricerca (es. atterro da un'altra pagina e voglio una nuova ricerca)
    if (this.trigger_search) this.search()

    // refresh di pagina (non ho gli hotels nello state né nel localstorage)
    else if (this.hotels.length == 0) this.search()

    // ho gli hotels nello state e devo filtrarli (es. atterro da un'altra pagina ma NON voglio una nuova ricerca)
    else this.filter_results()
  },

  methods: {
    search(e = false) {
      const is_new_search = e.reset_search_results || this.trigger_search

      //this.$store.commit('searching', true)
      if (is_new_search) this.$store.commit('searching', true)                   // ridondante, lo lasciamo per sicurezza
      this.$store.commit('livebk/trigger_search', false)      // serve altrimenti la vista va in loop sul search()

      if (e !==false) {
        if (e.reset_page_filters) this.reset_page_filters()
        if (e.reset_advanced_filters) this.reset_advanced_filters()
      }

      let timestamp_start = (new Date()).getTime()

      let salabam_categories = [],
        search_params = {
          checkin: this.checkin,
          checkout: this.checkout,
          suggestion: this.location,
          occupancy: this.occupancy,
        }

      // salabam_categories
      this.filter_hotel_categories.forEach(c => {
        salabam_categories.push(c)
      })
      if (salabam_categories.length > 0) search_params.salabamCategory = salabam_categories

      // min-max price
      if (this.filter_price_range.min !== this.min_price_bound && this.filter_price_range.min > 0) search_params.min_price = this.filter_price_range.min
      if (this.filter_price_range.max !== this.max_price_bound && this.filter_price_range.max > 0) search_params.max_price = this.filter_price_range.max

      // min review score
      if (this.advanced_filters.filter_review_score) search_params.min_review_score = this.advanced_filters.filter_review_score

      let current_search = encode_parameters(search_params)

      this.$store.commit('livebk/current_search', current_search)
      
      if (is_new_search) {
        //this.log('NEW SEARCH')
        this.$store.commit('livebk/hotels', [])
        this.offset = 0
        this.filtered = {}
      }

      else {
        this.appending = true
      }

      service_handler(
        'search',
        {
          ...search_params,
          offset: this.offset,
        },
      ).then(
        (success) => {
          this.total_properties = success.data.totalProperties
          this.offset = success.data.offset
          let hotels = [
            ...this.hotels,
            ...success.data.results,
          ]

          // delay controllato per evitare ricerche troppo veloci
          let gap = (new Date()).getTime() - timestamp_start,
            min_gap = 1500,
            delay = gap < min_gap ? (min_gap - gap) : 0

          setTimeout(() => {
            hotels.forEach((h) => {
              let price = h.extra_charge.reduce((total, extra) => {
                return subtract(total, (extra.type === "CITYTAX" ? Number(extra.amount) : 0))
              }, Number(h.price))
              h.live_price = markup(price).live_price
            })
            this.$store.commit('livebk/hotels', hotels)
            this.$store.commit('searching', false)
            this.appending = false
            this.filtered = this.filter_results()
          }, delay)
        },
        () => {
          this.$store.commit('searching', false)
        }
      )    
    },
    filter_results(hotels = this.hotels) {
      //this.filtering = true

      let filtered = {
        results: hotels,
      }

      // availability
      filtered.availability = filtered.results
       if (this.filter_availability) {
        let availability = this.get_availability().total
        filtered.availability = filtered.results.filter(h => { return smallerEq(number(h.live_price), availability) })
        filtered.results = filtered.availability
      }

      // refundable
      filtered.refundable = filtered.results
      filtered.refundable = filtered.results.filter(h => { return h.refundable })
      if (this.filter_refundable) {
        filtered.results = filtered.refundable
      }

      // preferred
      filtered.preferred = filtered.results
      filtered.preferred = filtered.results.filter(h => { return h.preferred })
      if (this.filter_preferred) {
        filtered.results = filtered.preferred
      }

      // stars
      filtered.stars = filtered.results 
      this.get_stars_options(filtered.results)
      let selected_stars = this.filter_stars.map(f => {
        if (f.selected) return f.value
      }).filter(Boolean)  // il costruttore Boolean ritorna falsy e rimuove gli undefined
      if (selected_stars.length > 0) {
        filtered.stars = filtered.results.filter(h => {
          return selected_stars.includes(h.stars)
        })
        filtered.results = filtered.stars
      }

      // amenities
      this.get_amenities_options(filtered.results)
      let selected_amenities = this.filter_amenities.map(f => {
        if (f.selected) return f.key
      }).filter(Boolean)  // il costruttore Boolean ritorna falsy e rimuove gli undefined
      if (selected_amenities.length > 0) {
        filtered.amenities = filtered.results.filter(h => {
          let property_amenities = []
          Object.keys(h.propertyAmenities).forEach(key => {
            let amenity_value = h.propertyAmenities[key]
            if (amenity_value == true) property_amenities.push(key)
          })
          return selected_amenities.every(s => {
            return property_amenities.includes(s)
          })
        })
        filtered.results = filtered.amenities
      }

      // sort results
      filtered.results = this.sort_items(filtered.results)
      this.filtered = filtered

      this.discard_advanced_filters()

      return filtered

      // finto timer solo per UI
      /*
      this.$nextTick(() => {
        setTimeout(() => {
          this.filtering = false
        }, 500)
      })
      */
      
    },
    active_filters() {
      let filters = {
        page_filters: [],
        advanced_filters: [],
      }
      // active page filters
      if (this.filter_refundable) {
        filters.page_filters.push({
          key: 'refundable',
          text: 'Solo rimborsabili',
          callback: this.update_filter_refundable,
        })
      }
      if (this.filter_preferred) {
        filters.page_filters.push({
          key: 'preferred',
          text: 'Preferiti da Booking',
          callback: this.update_filter_preferred,
        })
      }
      this.filter_stars.filter(f => { return f.selected }).forEach(f => {
        filters.page_filters.push({
          key: 'stars',
          filter: f,
          text: f.text,
          callback: this.update_filter_stars,
        })
      })
      this.filter_amenities.filter(f => { return f.selected }).forEach(f => {
        filters.page_filters.push({
          key: 'amenities',
          filter: f,
          text: f.text,
          callback: this.update_filter_amenities,
        })
      })

      // active advanced filters
      if (this.filter_hotel_categories) {
        this.filter_hotel_categories.forEach(f => {
          filters.advanced_filters.push({
            advanced_key: 'categories',
            key: 'categories'+f,
            text: Object.values(hotel_categories)[f-1].name,
            callback: () => {
              this.filter_hotel_categories = this.filter_hotel_categories.filter(h => {
                return h !== f
              })
              this.$store.commit('livebk/hotels', [])
              this.offset = 0
              this.filtered = {}
              this.search()
            }
          })
        })
      }
      if (this.filter_price_range.min || this.filter_price_range.max) {
        filters.advanced_filters.push({
          advanced_key: 'price_range',
          key: 'price_range',
          text: '€' + (this.filter_price_range.min ? this.filter_price_range.min : this.min_price_bound) + ' - €' + (this.filter_price_range.max ? this.filter_price_range.max : this.max_price_bound),
          callback: () => {
            this.filter_price_range.min = 0
            this.filter_price_range.max = 0
          }
        })
      }
      if (this.filter_review_score) {
        filters.advanced_filters.push({
          advanced_key: 'review_score',
          key: 'review_score',
          text: this.review_score_values.filter(r => { return r.value == this.filter_review_score})[0].label,
          callback: () => {
            this.filter_review_score = 0
          }
        })
      }

      return {
        ...filters,
        length: filters.advanced_filters.length + filters.page_filters.length,
      }
    },
    get_filter_option_name(key, value) {
      switch (key) {
        case 'stars':
          if (value == -1) return "Nessuna stella"
          return value == 1 ? '1 Stella' : value + ' Stelle'
      }
      return value
    },
    get_stars_options(hotels, sort = 'value', key = 'stars', callback = null) {

      if (hotels.length == 0) return {}
      let filters = {}

      // preprocess hotels / add default values
      hotels.map(h => {
        if (h.stars == '') h.stars = '-1'
      })

      hotels.forEach(h => {
        let option_index, selected
        if (filters[key] == undefined) filters[key] = []
        option_index = filters[key].findIndex(s => { return s.value === h[key] })
        if (option_index > -1) {
          let amount = filters[key][option_index].amount
          filters[key][option_index].amount = amount + 1
        }
        else {
          selected = false
          let item = this['filter_'+key].filter(f => { return h[key] == f.value})
          if (item.length) selected = item[0].selected ? true : false
          filters[key].push({
            key: h[key],
            text: this.get_filter_option_name(key, h[key]),
            value: h[key],
            amount: 1,
            selected: selected,
          })
        }
      })
      // callback
      if (callback) filters[key] = callback(filters[key])
      // sort
      if (sort) filters[key] = filters[key].sort((a,b) => { return b[sort] - a[sort] })
      this.$store.commit('livebk/filter_'+key, filters[key])
    },
    get_amenities_options(hotels, sort = 'value', key = 'amenities', callback = null) {

      if (hotels.length == 0) return {}
      let filters = {}

      hotels.forEach(h => {
        let option_index, selected
        if (filters[key] == undefined) filters[key] = []
        else {
          Object.keys(h.propertyAmenities).forEach(a => {
            option_index = filters[key].findIndex(s => { return s.key === a })
            let value = number(h.propertyAmenities[a])
            if (!value) return
            if (option_index > -1) {
              let amount = filters[key][option_index].amount
              filters[key][option_index].amount = amount + 1
            }
            else {
              selected = false
              let item = this['filter_'+key].filter(f => { return f.key == a })
              if (item.length) selected = item[0].selected ? true : false
              filters[key].push({
                key: a,
                text: get_amenities(a).name,
                value: value,
                amount: 1,
                selected: selected,
              })
            }
          })
        }
      })
      // callback
      if (callback) filters[key] = callback(filters[key])
      // sort
      if (sort) filters[key] = filters[key].sort((a,b) => { return b[sort] - a[sort] })
      this.$store.commit('livebk/filter_'+key, filters[key])
    },    
    update_filter_refundable(value) {
      this.filter_refundable = value
      this.filter_results()
    },
    update_filter_preferred(value) {
      this.filter_preferred = value
      this.filter_results()
    },
    update_filter_stars(option) {
      let filter_key = 'filter_stars',
        filter_store_key = 'livebk/'+filter_key,
        filter = this[filter_key].map(f => {
        return {
          ...f,
          selected: f.key == option.key ? option.selected : f.selected
        }
      })
      this.$store.commit(filter_store_key, filter)
      this.filter_results()
    },
    update_filter_amenities(option) {
      let filter_key = 'filter_amenities',
        filter_store_key = 'livebk/'+filter_key,
        filter = this[filter_key].map(f => {
        return {
          ...f,
          selected: f.key == option.key ? option.selected : f.selected
        }
      })
      this.$store.commit(filter_store_key, filter)
      this.filter_results()
    },
    reset_page_filters() {
      this.filter_refundable = false
      this.filter_amenities = this.filter_amenities.map(f => { return f.selected = false })
      this.filter_stars = this.filter_stars.map(f => { return f.selected = false })
      this.filter_availability = false
      this.filter_preferred = false
      this.discard_advanced_filters()
      this.filter_results()
    },
    update_price_range(min_value=false, max_value=false) {
      this.updating_price_range = true

      let lower_bound = 10,   // arbitrario, per evitare che vengano inseriti numeri senza senso
        upper_bound = 10000,  // arbitrario, per evitare che vengano inseriti numeri senza senso
        current_min = this.advanced_filters.filter_price_range.min,
        current_max = this.advanced_filters.filter_price_range.max
      
      if (min_value !== false) // condizione strict perché potrebbe essere stato digitato "0"
      {
        min_value = min(number(min_value), current_max, upper_bound)
        min_value = max(min_value, lower_bound)
        max_value = max(current_max, min_value, lower_bound)
        this.focus_max_price = true
      }

      else if (max_value !== false) // condizione strict perché potrebbe essere stato digitato "0"
      {
        max_value = max(number(max_value), current_min, lower_bound)
        max_value = min(max_value, upper_bound)
        min_value = min(current_min, max_value, upper_bound)
        this.focus_min_price = true
      }

      this.advanced_filters.filter_price_range.min = floor(min_value)
      this.advanced_filters.filter_price_range.max = floor(max_value)

      this.$nextTick(() => {
        this.focus_min_price = false
        this.focus_max_price = false
        this.$nextTick(() => {
          this.updating_price_range = false
        })
      })
    },
    update_filter_review_score(score, value) {
      this.$nextTick(() => {
        this.advanced_filters.filter_review_score = value ? score.value : 0
        this.filter_review_update_key = this.filter_review_update_key + 1
        this.submit_advanced_filters()
      })
    },
    reset_advanced_filters() {
      this.filter_hotel_categories = []
      this.filter_price_range = {
        min: 0,
        max: 0,
      }
      this.filter_review_score = 0
      //this.submit_advanced_filters()
    },
    reset_all_filters() {
      this.$nextTick(() => {
        this.reset_advanced_filters()
        this.reset_page_filters()
        this.submit_advanced_filters()
        //this.search()
      })
    },
    discard_advanced_filters() {
      this.advanced_filters = {
        filter_hotel_categories: [...this.filter_hotel_categories],
        filter_price_range: { min: this.min_price_bound, max: this.max_price_bound, },
        filter_review_score: this.filter_review_score,
      }
    },
    submit_advanced_filters() {
      let filters = {
        ...this.advanced_filters,
        filter_price_range: {
          min: this.advanced_filters.filter_price_range.min == this.min_price_bound ? 0 : (this.advanced_filters.filter_price_range.min == 0 ? 0 : this.advanced_filters.filter_price_range.min),
          max: this.advanced_filters.filter_price_range.max == this.max_price_bound ? 0 : (this.advanced_filters.filter_price_range.max == 0 ? 0 : this.advanced_filters.filter_price_range.max),
        }
      }
      Object.keys(filters).forEach(f => {
        this.$store.commit('livebk/'+f, filters[f])
      })
      this.search({
        reset_search_results: true,
      })
    },
    sort_items(items) {
      if (!items) return []
      let sort_item = this.sort_values.filter(s => { return s.key == this.sort_by })[0]
      if (sort_item.length == 0) return []
      if (!sort_item.sort) return items
      return items.sort(sort_item.sort)
    },
    toggle_filters() {
      document.body.classList.toggle('noscroll')
      this.show_filters = !this.show_filters
    }
  },
}
</script>

<style lang="scss">
.filters {
  &__sticky-wrapper {
    position: sticky;
    align-self: flex-start;
    top: 0;
    min-height: 100vh;
    overflow-y: scroll;
    @include hide-scrollbar;
  }
  
  &__btn {

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-title {

      @include media-breakpoint-up(xl) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 500px;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 650px;
      }
    }
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $vb-font-weight-semibold;
  }

  &__section__title {
    font-weight: $vb-font-weight-semibold;
  }

  &__section__subtitle {
    display: block;
    font-size: $font-size-sm;
    line-height: 1.2em;
    margin-top: 0.1em;
  }

  &__panel {
    background: $white;
    border-radius: $border-radius-sm;
    padding: $grid-gutter-width/2;
    .vb-check {
      &__title {
        font-size: $font-size-md;
      }
    }  

    &>*:last-child {
      //margin-bottom: $grid-gutter-width/4;
    }
  }

  &__hotel-categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    &__item {
      display: flex;
      align-items: center;
      //justify-content: center;
      min-width: 100%;
      text-align: center;
      border: 1px solid $gray-200;
      border-radius: $btn-border-radius;
      &:not(:last-child) {
        margin-bottom: $grid-gutter-width/4;
      }
      padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
      transition: $transition-base;
      cursor: pointer;
      &:hover, &.active {
        background-color: $gray-100;
        border-color: $gray-300;
      }
      &.active {
        background-color: $gray-200;
        border-color: $gray-400;
      }
      width: calc(50% - #{$grid-gutter-width/4});
      font-size: $font-size-md;
      line-height: 120%;
      @include media-breakpoint-down(sm) {
        min-height: 55px;
      }
    }
    &__icon {
      margin-right: 8px;
    }
    &__name {
      color: $dark;
    }    
  }

  &__price-range {
    display: block;
    &__item {
      width: 50%;
      display: inline-flex;
      align-items: center;
      &:first-child {
        &:after {
          content: "";
          width: 20px;
        }
      }
      .vee--errors {
        display: none !important;
      }
    }
    &__label {
      color: $gray-500;
      padding-right: 1rem;
    }
    @include media-breakpoint-up(sm) {
      &__item {
        width: auto;
        &:first-child {
          &:after {
            content: "";
            width: 60px;
            height: 1px;
            background-color: $gray-300;
            margin: 0 20px;
          }
        }
      }
    }

  }

  &__review-score {
    display: flex;
    flex-direction: column-reverse;
    &__item {
      min-width: 100%;
    }
  }

  &__advanced-badge {
    border-radius: $border-radius-sm;
    background-color: $white;
    transition: $transition-base;
    font-weight: $font-weight-normal;
    color: $dark;
    &:hover, &:focus {
      background-color: $gray-200;
    }
  }

  &__counter {
    position: absolute;
    right:0;
    top: 0;
    transform: translateY(-50%);
    font-size: $font-size-sm;
    background: $dark;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: $light !important;
    line-height: 120%;
    font-weight: $font-weight-bold;
  }

  @include media-breakpoint-down(md) {
    &__sticky-wrapper {
      position: static;
      flex: auto;
      max-width: 90vw;
      position: fixed;
      top:0;
      bottom: 0;
      height: 100vh;
      left: 10vw;
      z-index: 999; // la modal ha 1030, il pannello deve stare dietro
      padding: 0;
      transform: translateX(100%);
      transition: $transition-base;
      .toggle-map {
        display: none;
      }
      &.visible {
        transform: translateX(0);
      }
    }

    &__content-wrapper {
      padding: $grid-gutter-width/4 $grid-gutter-width/2;
      background-color: $white;
      position: relative;
      min-height: 100%;
    }

    &__btn-wrapper {
      flex-wrap: wrap;
      flex-direction: column;
      &>* {
        min-width: 100%;
      }
    }

    &__btn-content {
      min-width: 100%;
      display: flex;
      justify-content: center;
      margin-top: $grid-gutter-width/2;
    }

    &__btn-title {
      min-width: 100%;
      margin-bottom: 0;
      text-align: center;
    }

    &__btn-resume {
      text-align: center;
    }

    &__btn-order {
      margin-right: $grid-gutter-width/4;
    }

    &__btn-filter {
      display: none;
    }

    &__btn-filter-mobile {
    }

    &__btn-advanced-mobile {
      margin-top: 0;
      margin-bottom: $grid-gutter-width/4;
    }

    &__panel {
      padding-left: 0;
    }

    &__active-filters {
      display: none;
    }
  }

}

.results {
  &__map {
    max-height: 80vh;
    height: 320px;
    @include media-breakpoint-up(sm) {
      height: 400px;
    }
    @include media-breakpoint-up(md) {
      height: 600px;
    }
  }
  &__list {}
}

.variant-white {
  position: relative;
  .btn,
  &.btn {
    padding-right: 1.25em;
    padding-left: 1.25em;
    background-color: $white;
    color: $dark;
    border-color: transparent;
    * {
      color: $dark;
    }
    svg {
      --color: #{$dark};
    }

    &:after {
      filter: brightness(0);
    }

    &:hover, &:focus {
      border-color: transparent;
      background-color: $gray-200;
      color: $dark !important;
    }
  }

  .dropdown-menu {
    border: 1px solid $gray-200;
    font-size: $font-size-md;
    margin:0;
    padding: 0.75rem 0;
    a {
      color: inherit;
    }
    .active {
      color: $info;
    }
  }
}

.info-window {
  padding:6px 0 6px 6px;
  display: flex;
  flex-direction: column;
  font-size: $font-size-md;
  * {
    line-height: 120%;
  }
  &>*:not(:last-child) {
    margin-bottom: $grid-gutter-width/2;
  }
  &__image {
    width: 100%;
    height: 100px;
    overflow: hidden;
    border-radius: $border-radius-sm;
    img {
      width:100%;
      height: auto;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: $grid-gutter-width/4;
    }
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .right {
      text-align: right;
      padding-left: 8px;
      font-size: $font-size-lg;
      font-weight: $vb-font-weight-semibold;
      color: $primary;
      display: flex;
      align-items: center;
    }
    .address {
      width:100%;
      font-size: $font-size-sm;
    }
    .name {
      width:100%;
      font-size: $font-size-md;
      font-weight: $vb-font-weight-semibold;
    }
    .ratings {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .stars {
      display: flex;
      align-items: center;
      flex: 2;
      svg {
        width: 16px;
        height: auto;
        margin-right: 4px;
      }
    }
    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $vb-font-weight-semibold;
      color: $info;
      text-align: center;
      flex: 1;
    }
    .copy {
      font-size: $font-size-sm;
      font-weight: $vb-font-weight-semibold;
      color: $secondary;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      svg {
        width: 16px;
        height: auto;
        margin-right: 4px;
        transform: translateY(-1px);
        g {
          fill: #{$secondary};
        }
      }
    }
  }

  &__button {
    .btn {
      display: block;
      width: 100%;
    }
  }
}

body,html {
  &.noscroll {
    overflow: hidden;
  }
}
</style>