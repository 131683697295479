<template>
  <div class="rooms-modal">
    <rooms-carousel :room="room" />

    <p class="rooms-modal__title" v-if="room.room_photos" v-html="room.room_name" />
    <div class="rooms-modal__wrap">
      <div class="rooms-modal__bed">
        <vb-icon class="rooms-modal__bed-icon mr-1" :name="'doublebed'" :color="'000'" :size="16" />
        <span class="rooms-modal__bed-text" v-html="room.room_info.bedrooms.map(bed => `${bed.description.replace('/', ' oppure ')}`).join(', ')" />
      </div>
      <div class="rooms-modal__cots">
        <span class="rooms-modal__cots-text" v-html="'Culle e letti supplementari disponibili su richiesta'" />
        <vb-icon class="rooms-modal__cots-icon ml-1" :name="'info'" :color="sass('gray-500')" :size="13" v-b-popover.hover.top="'Per maggiori informazioni visita la sezione: Condizioni per i bambini e letti supplementari'" />
      </div>
    </div>

    <p class="rooms-modal__desc" v-html="room.room_description" />

    <rooms-info :room="room" />

    <div class="rooms-modal__facilities" :class="show_facilities && 'rooms-modal__facilities--open'">
      <p class="rooms-modal__facilities-title" v-html="'Servizi'" />
      <ul class="rooms-modal__facilities-list">
        <li class="rooms-modal__facilities-list_item"
            v-for="(facility, facility_index) in room.room_facilities"
            :key="_uid + 'facility' + facility_index"
        >
          <vb-icon class="rooms-modal__facilities-list_icon" :name="'check_circle'" :color="'f1ae04'" :size="14" />
          <span class="rooms-modal__facilities-list_text" v-html="facility.name" />
        </li>
      </ul>
    </div>

    <span v-if="room.room_facilities.length > 15" class="rooms-modal__show" v-html="!show_facilities ? 'vedi tutti' : 'chiudi'" @click="toggle_facilities()" />
  </div>
</template>

<script>
import RoomCarousel from '@booking/components/blocks/detail/rooms-carousel.vue'
import RoomsInfo from '@booking/components/blocks/detail/rooms-info.vue'

export default {
  name: 'rooms-modal',

  components: {
    'rooms-carousel': RoomCarousel,
    'rooms-info': RoomsInfo,
  },

  props: {
    room: Object
  },

  data() {
    return {
      show_facilities: false
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
    toggle_facilities() {
      this.show_facilities = !this.show_facilities
    },
  },
}
</script>

<style lang="scss" scoped>

  .rooms-modal {

    &__title {
      font-size: $font-size-xl;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 0;
    }

    &__wrap {
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

    &__wrap, &__bed, &__cots {
      display: flex;
      align-items: center;
    }

    &__bed {
      align-items: flex-start;
      line-height: normal;
      margin-right: 10px;

      &-icon {
        margin-top: 3px;
      }

      &-text {
        font-weight: $vb-font-weight-semibold;
        color: $secondary;
      }
    }

    &__cots {
      margin-top: 3px;
      font-size: $font-size-md;
    }

    &__facilities {
      border-top: 1px solid $gray-200;
      margin-top: 20px;
      padding-top: 20px;
      max-height: 200px;
      overflow: hidden;

      &--open {
        max-height: none;
      }

      &-title {
        font-size: $font-size-lg;
        font-weight: $vb-font-weight-semibold;
        margin-bottom: 15px;
      }

      &-list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        &_item {
          list-style: none;
          line-height: normal;
          margin: 4px 0;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: $font-size-md;

          @include media-breakpoint-up(md) {
            width: 50%;
          }

          @include media-breakpoint-up(lg) {
            padding: 2px 20px 2px 0;
            margin: 2px 5px 2px 0;
            width: 32%;
          }
        }

        &_icon {
          margin-top: 2px;
          margin-right: 10px;
        }
      }
    }

    &__show {
      display: block;
      text-align: center;
      color: $info;
      text-decoration: underline;
      font-size: $font-size-md;
      cursor: pointer;
      justify-content: flex-end;
      margin-top: 15px;
    }
  }

  ::v-deep {

    .rooms {
      &-info {
        &__name {
          font-size: $font-size-md;
        }
      }
    }
  }

</style>