<template>
  <section :id="$options.name" class="info">
    <template v-for="(hotel, hotel_index) in hotels">
      <div v-if="hotel.hotel_data.hotel_important_information !== ''" :key="_uid+'info'+hotel_index">
        <h2 class="info__title" v-html="'Da sapere'" />

        <div class="info__text">
          <span v-html="hotel.hotel_data.hotel_important_information.replaceAll('.', '.<br>')" />
          <div class="info__license" v-if="hotel.hotel_data.license_number !== ''">
            <span class="info__license-title" v-html="'Numero di licenza: '" />
            <span class="info__license-number" v-html="hotel.hotel_data.license_number" />
          </div>
        </div>

        <key-info class="info__key" :hotel="hotel.hotel_data" />
      </div>
    </template>
  </section>
</template>

<script>
import KeyInfo from '@booking/components/blocks/key-info.vue'

export default {
  name: 'info',

  components: {
    'key-info': KeyInfo,
  },

  props: {
    hotels: Array,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .info {
    @extend %section;
    @extend %section_margin;

    &__title {
      @extend %section__title;
    }

    &__text {
      background-color: $white;
      border-radius: 10px;
      margin-bottom: 0;
      padding: 20px;

      @include media-breakpoint-up(xl){
        line-height: $font-size-base * 2;
      }
    }

    &__license {
      display: flex;
      align-items: center;
      font-size: $font-size-md;
      margin-top: 5px;

      &-title {
        font-weight: $vb-font-weight-semibold;
        margin-right: 5px;
      }
    }

    &__key {
      @extend .info__text;
      margin-top: $grid-gutter-width;
    }
  }

</style>