<template>
  <div class="cots-extrabeds" v-if="this.hotel.additional_policies.cots_and_extra_beds">
    <div class="cots-extrabeds__element"
         v-for="(values, key) in group_by_rate_type(this.hotel.additional_policies.cots_and_extra_beds)"
         :key="_uid + key"
    >
      <p class="cots-extrabeds__element-title" v-html="get_cots_extrabeds(key).name" />
      <div class="cots-extrabeds__element-content">
        <div class="cots-extrabeds__item" v-for="(extra_bed, extra_bed_index) in values" :key="_uid + extra_bed_index">
        <div class="cots-extrabeds__item-wrap">
          <p class="cots-extrabeds__item-title">
            <strong>da {{extra_bed.from_age}} <span v-if="!extra_bed.to_age">anni e oltre</span></strong>
            <strong v-if="extra_bed.to_age" v-html="'a ' + extra_bed.to_age + ' anni'"></strong>
          </p>
          <div class="cots-extrabeds__item-content">
            <div class="d-flex align-items-center">
              <vb-icon :name="get_cots_extrabeds(extra_bed.rate_type).icon" :size="16" />
              <span class="ml-1" v-html="get_cots_extrabeds(extra_bed.rate_type).name" />
              <vb-icon v-if="get_cots_extrabeds(extra_bed.rate_type).tooltip !== ''"
                       class="ml-1 mt-1" :name="'info'" :color="sass('gray-500')" :size="12"
                       v-b-popover.hover.top="get_cots_extrabeds(extra_bed.rate_type).tooltip"
              />
            </div>
            <div v-if="extra_bed.price !== 0">
              <span v-html="'<em class=\'text--sm\'>' + hotel.currency + '</em>' + ' ' + extra_bed.price" />
              <span v-if="extra_bed.price_mode === 'per_night'" v-html="' a notte'" />
            </div>
            <span v-else v-html="'Gratis'" />
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_cots_extrabeds } from '@booking/resources'

export default {
  name: 'cots-extrabeds',

  components: {
  },

  props: {
    hotel: Object,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
    this.get_cots_extrabeds = get_cots_extrabeds
  },

  mounted() {
  },

  methods: {

    group_by_rate_type(data) {
      const result = {}

      data.forEach(item => {
        const rate_type = item.rate_type

        if (!result[rate_type]) {
          result[rate_type] = []
        }

        result[rate_type].push(item)
      })

      return result
    }

  },
}
</script>

<style lang="scss" scoped>

  .cots-extrabeds {

    &__element {
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }

      &-title {
        margin-bottom: 0;
        padding: 0 3px;
        line-height: normal;
        font-weight: $vb-font-weight-semibold;
        color: $gray-600;
      }

      &-content {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
      }
    }

    &__item {
      padding: 10px 5px 0 5px;
      flex-basis: 50%;

      &-wrap {
        border: 1px solid $gray-200;
        border-radius: 10px;
        overflow: hidden;
      }

      &-title {
        border-bottom: 1px solid $gray-200;
        padding: 5px 15px;
        margin-bottom: 0;
        background-color: #f0f6ff;

        strong {
          font-weight: $vb-font-weight-semibold;
        }
      }

      &-content {
        display: flex;
        padding: 10px 15px;
        background-color: #fafcff;

        & > * {

          &:first-child {
            flex-basis: 70%;
          }

          &:last-child {
            text-align: right;
            flex-basis: 30%;
          }
        }
      }
    }
  }

</style>