<template>
  <div :id="$options.name" class="heading">
    <div v-for="(hotel, hotel_index) in hotels" :key="_uid+'_heading_'+hotel_index">
      <div class="heading__wrap">
        <div class="heading__element heading__element--first">
          <div class="d-flex align-items-center">
            <vb-icon class="heading__icon heading__icon--like" v-if="hotel.hotel_data.preferred" v-b-popover.hover.top="'Pensato per offrire un ottimo servizio e un buon rapporto qualità-prezzo.'" :name="'like'" :color="sass('secondary')" :size="18" />
            <div class="d-flex">
              <h1 class="heading__title" v-html="hotel.hotel_data.name" />
              <b-form-rating
                  class="heading__star"
                  v-if="hotel.hotel_data.class !== 0"
                  no-border inline readonly
                  size="md"
                  variant="secondary"
                  :value="hotel.hotel_data.class"
              />
            </div>
          </div>
          <p class="heading__address">
            <vb-icon class="heading__icon mr-1" :name="'symbol_lb'" :color="'053B95'" :size="14" />
            <span v-html="hotel.hotel_data.address + ', ' + hotel.hotel_data.zip + ' ' + hotel.hotel_data.city" />
            <span class="heading__address-map" v-html="'vedi mappa'" v-b-modal="'modal_map'" />
          </p>
        </div>
        <div class="heading__element heading__element--second">

          <score class="heading__score" :hotel="hotel.hotel_data" direction="row" />

          <router-link :to="{ name: 'detail', hash: '#rooms' }">
            <vb-button
                class="semibold"
                :vb_options="{
                  text: 'Prenota Ora',
                  variant: 'info',
                  size: 'sm',
                }"
            />
          </router-link>
        </div>
      </div>
      <vb-modal :vb_options="{
        id: 'modal_map',
        size: 'xl',
        title: hotel.hotel_data.name,// + ' - ' + hotel.hotel_data.address,
        hide_footer: true,
      }">
        <detail-google-map
          :map_markers="map_markers([detail_hotel], availability, sass)"
          :map_location="{
            latitude: number(detail_hotel.location.latitude),
            longitude: number(detail_hotel.location.longitude),
          }"
        />
      </vb-modal>        
    </div>
  </div>
</template>

<script>
import GoogleMap from '@components/blocks/map'
import { map_markers } from '@booking/utilities'
import { number } from 'mathjs'
import Score from "@booking/components/atoms/score.vue";

export default {
  name: 'heading',

  components: {
    'detail-google-map': GoogleMap,
    'score': Score,
  },

  props: {
    hotels: Array,
    availability: String,
    detail_hotel: Object,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
    this.number = number
    this.map_markers = map_markers
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .heading {

    &__wrap {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include media-breakpoint-up(md){
        flex-direction: row;
        align-items: center;
      }
    }

    &__element {

      &--second {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid $gray-300;

        @include media-breakpoint-up(md){
          display: block;
          margin-top: 0;
          padding-top: 0;
          border: none;
          text-align: right;
        }
      }
    }

    &__icon {
      &--like {
        background-color: $white;
        padding: 3px;
        border-radius: $border-radius-lg;
        margin-right: 10px;
      }
    }

    &__title {
      margin-bottom: 0;
    }

    &__star {
      margin-left: 5px;
      margin-top: -15px;
      width: auto;
    }

    &__address {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      &-map {
        color: $info;
        margin-left: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__score {
      margin-bottom: 10px;
    }
  }


  .map {
    max-height: 80vh;
    height: 320px;
    @include media-breakpoint-up(sm) {
      height: 400px;
    }
    @include media-breakpoint-up(md) {
      height: 600px;
    }
  }

</style>