<template>
  <div class="pad">
    <b-row :class="'hidkey_' + hotel.hotel_id"><!-- per identificare chiaramente pad/hotel -->
      <b-col class="pad__column pad__column--first" cols="12" lg="3">
        <div class="pad__image"
             :style="'background-image:url('+ hotel.imageUrl +'), url(\'/assets/images/common/statics/room_placeholder.png\')'"
        >
          <span class="pad__image-corner"></span>
        </div>
      </b-col>
      <b-col class="pad__column pad__column--second" cols="12" lg="9">
        <div class="pad__wrap">
          <div class="pad__wrap-element pad__wrap-column--first">
            <div class="pad__head">
              <div class="pad__head-wrap">
                <vb-icon class="pad__head-icon pad__head-icon--like" v-if="hotel.preferred" v-b-popover.hover.top="'Pensato per offrire un ottimo servizio e un buon rapporto qualità-prezzo.'" :name="'like'" :color="sass('secondary')" :size="15" />
                <h2 class="pad__head-name" v-html="hotel.name" />
                <div class="pad__head-star" v-if="hotel.stars > 0">
                  <b-form-rating
                      no-border inline readonly
                      size="sm"
                      variant="secondary"
                      :value="hotel.stars"
                  />
                </div>
                <p v-if="get_categories(hotel.salabamCategory) !== undefined" class="pad__head-category" v-html="get_categories(hotel.salabamCategory)" />
              </div>
              <p class="pad__head-locality">
                <span>{{ hotel.full_address | capitalize }}</span>
              </p>
              <p class="pad__head-abstract" v-html="hotel.hotel_description.slice(0, 190) + '...'" />
            </div>
            <score :hotel="hotel" />
            <div class="pad__push">
              <template v-for="(amenity_value, amenity_key, amenity_index) in sort_amenities(hotel.propertyAmenities)">
                <div class="pad__push-btn" v-if="amenity_index < 3 && amenity_value && get_amenities(amenity_key).visible" :key="_uid+'amenities'+amenity_key+amenity_index">
                  <vb-icon
                      class="pad__push-icon"
                      v-b-popover.hover.top="get_amenities(amenity_key).name"
                      :name="get_amenities(amenity_key).icon"
                      :color="sass('dark')"
                      :size="16"
                  />
                </div>
              </template>
              <div class="pad__push-btn" v-b-modal="_uid+'modal_amenities'" v-if="Object.values(hotel.propertyAmenities).filter(value => value === '1').length > 3">
                <vb-icon
                    class="pad__push-icon"
                    :name="'dotted'"
                    :color="sass('dark')"
                    :size="16"
                />
              </div>
              <vb-modal
                  :vb_options="{
                      id: _uid+'modal_amenities',
                      body_class: 'pad__modal',
                      title: 'Principali Servizi',
                      size: 'md',
                      centered: true,
                      hide_footer: true
                    }"
              >
                <div class="pad__modal-wrap">
                  <template v-for="(amenity_value, amenity_key) in sort_amenities(hotel.propertyAmenities)">
                    <div class="pad__modal-item" v-if="amenity_value === '1'" :key="_uid+'amenities'+amenity_key">
                      <vb-icon
                          class="pad__modal-item_icon"
                          :name="get_amenities(amenity_key).icon"
                          :color="'053B95'"
                          :size="16"
                      />
                      <span class="pad__modal-item_label" v-html="get_amenities(amenity_key).name" />
                    </div>
                  </template>
                </div>
              </vb-modal>
            </div>
          </div>
          <div class="pad__wrap-element pad__wrap-column--second">
            <div class="pad__room">
              <div class="pad__room-element pad__room-element--first">
                <div class="pad__room-head">
                  <h3 class="pad__room-name" v-html="hotel.room.room_name" />
                </div>
                <div class="pad__room-info">
                  <div class="pad__room-info_item pad__room-info_item--refundable" v-if="hotel.room.refundable" v-b-popover.hover.top="'Rimborsabile fino al ' + refundable_until_formatted">
                    <vb-icon class="pad__room-info_icon" :name="'check_circle'" :color="sass('success')" :size="14" />
                    <span class="pad__room-info_title" v-html="'Cancellazione gratuita'" />
                    <vb-icon class="pad__room-info_icon ml-1" :name="'info'" :color="sass('gray-500')" :size="12" />
                  </div>
                  <div class="pad__room-info_item pad__room-info_item--availability" v-if="hotel.room.num_rooms_available_at_this_price">
                    <span class="pad__room-info_title" :class="hotel.room.num_rooms_available_at_this_price === 1 && 'pad__room-info_title--alert'"
                          v-html="hotel.room.num_rooms_available_at_this_price + (hotel.room.num_rooms_available_at_this_price > 1 ? ' camere disponibili' : ' camera disponibile')"
                    />
                  </div>
                </div>
              </div>
              <div class="pad__room-element pad__room-element--second">
                <div class="pad__room-price">
                  <div class="pad__room-price_wrap">
                    <vb-icon class="pad__room-price_icon" :name="'euro'" :color="'053B95'" :size="15" />
                    <span class="pad__room-price_value" v-html="hotel.live_price" />
                  </div>
                  <span class="pad__room-price_tax" v-html="'include gestione e assistenza'" />
                </div>
                <custom-link
                    class="pad__room-cta"
                    :before_navigation="() => {
                      this.$store.commit('livebk/selected_hotel', hotel)
                    }"
                    :route_name="'detail'"
                    :css_class="'d-block'"
                    :as_default="get_config('guiSettings.openDetailNewTab', false)">
                >
                  <template #content>
                    <vb-button
                        class="pad__room-btn shadow"
                        :vb_options="{
                          text: 'Vedi disponibilità',
                          variant: 'info',
                          size: 'sm'
                        }"
                    />
                  </template>
                </custom-link>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { get_amenities, get_categories } from '@booking/resources'
import moment from 'moment'
import Score from "@booking/components/atoms/score.vue";

export default {
  name: "pad",

  components: {
    'score': Score,
  },

  props: {
    hotel: Object,
  },

  data() {
    return {
    }
  },

  computed: {

    refundable_until_formatted() {
      return moment(this.hotel.room.refundableBefore, "DD-MM-YYYY HH:mm:ss Z").format('DD-MM-YYYY')
    },

  },

  created() {
    this.get_amenities = get_amenities
    this.get_categories = get_categories
  },

  mounted() {
  },

  watch: {},

  methods: {

    sort_amenities(property_amenities) {
      const sorted_keys = Object.keys(property_amenities).sort((a, b) => {
        return property_amenities[b] - property_amenities[a]
      })

      const sorted_object = {}

      sorted_keys.forEach(key => {
        sorted_object[key] = property_amenities[key]
      })

      return sorted_object
    },
  },
}
</script>

<style lang="scss" scoped>

  .pad {
    position: relative;

    &__column {

      &--second {
        position: static !important;
      }
    }

    &__image {
      min-height: 300px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 5px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 102%, 85% 84%, 0 84%);
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, 101% 0, 91% 23%, 91% 100%, 0% 101%);
        min-height: 100%;
      }

      &-url {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
      }

      // trick angolo smussato
      &-corner {
        width: 30px;
        height: 30px;
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 16%;
        z-index: 1;
        transform: scaleX(-1);

        @include media-breakpoint-up(lg) {
          left: auto;
          right: 9%;
          bottom: 0;
          transform: scaleX(1);
        }

        &:before {
          content: "";
          width: 200%;
          height: 200%;
          position: absolute;
          border-radius: 7px;
          bottom: 0;
          right: 0;
          box-shadow: 50px 50px 0 0 $vb-body-bg;
        }
      }
    }

    &__wrap {
      background-color: $white;
      margin-top: -40px;
      padding: 15px 20px;
      border-radius: 0 5px 5px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: -40px;
        padding: 20px 60px 20px 20px;
      }

      &-column {

        &--first {
          @include media-breakpoint-up(lg) {
            display: flex;
          }
        }
      }
    }

    &__head {

      @include media-breakpoint-up(lg) {
        flex-basis: 80%;
      }

      &-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media-breakpoint-up(lg) {
          flex-wrap: nowrap;
          position: relative;
        }
      }

      &-icon {
        &--like {
          margin-right: 5px;
          display: none;

          @include media-breakpoint-up(lg) {
            display: block
          }
        }
      }

      &-name {
        font-size: $font-size-lg;
        font-weight: $vb-font-weight-semibold;
        margin-bottom: 0;
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
      }

      &-star {
        display: flex;
        margin-right: 5px;
      }

      &-category {
        color: $gray-600;
        font-size: $font-size-sm;
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        background-color: $white;
        padding: 4px 5px;
        border-radius: 5px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg){
          position: static;
          background-color: transparent;
          padding: 0;
          border-radius: 0;

          &:before {
            content: "("
          }
          &:after {
            content: ")"
          }
        }
      }

      &-locality {
        color: $gray-500;
        margin-bottom: 5px;
        font-size: $font-size-md;
      }

      &-abstract {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
          font-size: 14px;
          padding-bottom: 5px;
          margin-bottom: 0;
        }
      }
    }

    &__push {
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 10px 0 0;
      border-top: 1px solid $gray-200;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $primary;
        padding: 0 10px;
        margin-top: 0;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        border: none;
        overflow: hidden;
      }

      &:empty {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
          width: 36px;
          background-color: $primary;
        }
      }

      &-btn {
        background-color: $vb-body-bg;
        border-radius: 50%;
        padding: 10px;
        margin-right: 10px;

        @include media-breakpoint-up(lg) {
          background-color: transparent;
          border-radius: 0;
          padding: 10px 0 0;
          margin-right: 0;
          margin-top: 10px;
          border-top: 1px solid #3d66a5;
        }

        &:first-child {
          @include media-breakpoint-up(lg) {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        ::v-deep {
          // cambio colore icona desktop
          svg * {
            @include media-breakpoint-up(lg) {
              fill: $white;
            }
          }
        }
      }
    }

    &__modal {

      &-wrap {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        display: flex;
        align-items: center;
        flex-basis: 40%;
        margin: 3px 0;

        &_icon {
          margin-right: 5px;
        }
      }
    }

    &__room {
      $this: &;
      padding-top: 10px;
      margin-top: 10px;
      border-width: 1px 0 0;
      border-style: solid;
      border-color: $gray-300;

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding-top: 0;
        border-width: 0 0 0 1px;
        padding-left: 10px;
      }

      &-element {

        &--first {
          @include media-breakpoint-up(lg) {
            flex-basis: 60%;
          }
        }
        &--second {
          display: flex;
          margin-top: 20px;

          @include media-breakpoint-up(lg) {
            flex-basis: 40%;
            margin-top: 0;
          }
        }
      }

      &-head {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      &-name {
        font-size: $font-size-md;
        margin-bottom: 0;
        margin-right: 10px;
        padding: 2px 4px;
        border-radius: 4px;

        @include media-breakpoint-up(sm) {
          background-color: #f4f4f4;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 400px;
        }
      }

      &-info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 5px;

        &:empty {
          display: none;
        }

        &_item {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }

        &_title {
          margin-left: 5px;

          @at-root #{$this}-info_item--refundable & {
            color: $success;
            cursor: default;
          }

          @at-root #{$this}-info_item--availability & {
            &:before {
              content: "";
              width: 6px;
              height: 6px;
              background-color: $vb-body-color;
              display: inline-block;
              border-radius: 100%;
              margin-right: 5px;
              position: relative;
              top: -1px;
            }
          }

          @at-root #{$this}-info_item--availability &--alert {
            color: $danger;

            &:before {
              background-color: $danger;
            }
          }
        }
      }

      &-price {
        flex-basis: 50%;
        margin-right: 10px;

        @include media-breakpoint-up(lg) {
          margin-right: 20px;
          flex-basis: auto;
        }

        &_wrap {
          display: flex;
          align-items: center;
          justify-content: right;
          line-height: normal;
        }

        &_value {
          color: $primary;
          margin-left: 5px;
          font-weight: $vb-font-weight-semibold;
          font-size: 23px;

          @include media-breakpoint-up(lg) {
            font-size: 21px;
          }
        }

        &_tax {
          display: block;
          font-size: $font-size-sm;
          color: $gray-600;
          text-align: right;
          margin-top: -3px;
        }
      }

      &-cta {
        flex-basis: 50%;

        @include media-breakpoint-up(lg) {
          flex-basis: auto;
        }
      }

      &-nocredit {
        color: $secondary;
        font-size: $font-size-lg;
        display: flex;
        align-items: baseline;
        justify-content: center;
        font-weight: $vb-font-weight-semibold;
        padding-top: .625rem;
        transition: auto;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-sm;
        }
      }

      &-btn {
        margin-top: 5px;
        display: block;
        width: 100%;
        font-weight: $vb-font-weight-semibold;

        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
      }
    }
  }

</style>