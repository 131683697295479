var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"facilities",staticClass:"facilities",attrs:{"id":_vm.$options.name}},_vm._l((_vm.hotels),function(hotel,hotel_index){return _c('div',{key:_vm._uid+'facilities'+hotel_index},[_c('h2',{staticClass:"facilities__title",domProps:{"innerHTML":_vm._s(hotel.hotel_data.name + ': Servizi')}}),_c('p',{staticClass:"facilities__subtitle",domProps:{"innerHTML":_vm._s('Alcuni servizi potrebbero essere a pagamento.')}}),_c('ul',{staticClass:"facilities__list",class:_vm.show_facilities && 'facilities__list--open'},_vm._l((hotel.hotel_data.hotel_facilities),function(facility,facility_index){return _c('li',{key:_vm._uid + 'facility' + facility_index,staticClass:"facilities__list-item"},[_c('vb-icon',{staticClass:"facilities__list-icon",attrs:{"name":'check_circle',"color":'f1ae04',"size":14}}),_c('span',{staticClass:"facilities__list-text",domProps:{"innerHTML":_vm._s(facility.name)}})],1)}),0),(_vm.is_mobile())?_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal_facilities_' + hotel_index),expression:"'modal_facilities_' + hotel_index"}],staticClass:"facilities__show",domProps:{"innerHTML":_vm._s('vedi i servizi')}}):(hotel.hotel_data.hotel_facilities.length > 15)?_c('span',{staticClass:"facilities__show",domProps:{"innerHTML":_vm._s(!_vm.show_facilities ? 'vedi tutti i servizi' : 'chiudi')},on:{"click":function($event){return _vm.toggle_facilities()}}}):_vm._e(),_c('vb-modal',{attrs:{"vb_options":{
                id: 'modal_facilities_' + hotel_index,
                body_class: 'facilities__modal',
                title: hotel.hotel_data.name + ': Servizi',
                size: 'lg',
                centered: true,
                hide_footer: true
              }}},[_vm._l((hotel.hotel_data.hotel_facilities),function(facility,facility_index){return [_c('span',{key:_vm._uid + 'facility' + facility_index,staticClass:"facilities__modal-item",domProps:{"innerHTML":_vm._s(facility.name)}})]})],2),_c('div',{staticClass:"facilities__languages"},[_c('h3',{staticClass:"facilities__languages-title"},[_c('vb-icon',{staticClass:"facilities__languages-icon",attrs:{"name":'chat',"color":_vm.sass('primary'),"size":20}}),_c('span',{domProps:{"innerHTML":_vm._s('Lingue parlate:')}})],1),_vm._l((hotel.hotel_data.spoken_languages),function(lang,lang_index){return _c('span',{key:_vm._uid + 'lang' + lang_index,staticClass:"facilities__languages-text",domProps:{"innerHTML":_vm._s(_vm.get_languages(lang).label)}})})],2)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }