<template>
  <section :id="$options.name" class="carousel">
    <div v-for="(hotel, hotel_index) in hotels" :key="_uid+'_carousel_'+hotel_index">
      <div v-if="hotel.hotel_data.hotel_photos.length">

        <div class="carousel__main" v-b-modal="'modal_carousel'">
          <template v-for="(image, image_index) in hotel.hotel_data.hotel_photos">
            <div :class="'carousel__item carousel__item--' + (image_index+1)"
                 :style="'background-image:url('+ image.url_original + ')'"
                 v-bind:key="_uid+image_index" v-if="image_index < 6"
            ></div>
          </template>

          <div class="carousel__show" v-b-modal="'modal_carousel'">
            <vb-icon class="carousel__show-icon" :name="'gallery'" :color="sass('dark')" :size=14 />
            <span class="carousel__show-text" v-html="'Vedi tutte le foto'" />
          </div>
        </div>

        <vb-modal
            :vb_options="{
              id: 'modal_carousel',
              modal_class: 'carousel__modal',
              dialog_class: hotel.hotel_data.hotel_photos.length > 20 ? 'fullwidth' : '',
              title: hotel.hotel_data.name,
              size: 'xl',
              centered: true,
              hide_footer: true,
            }"
        >
          <div class="carousel__modal-wrap" ref="swiper-container">
            <div class="carousel__modal-item" v-for="(image, image_index) in hotel.hotel_data.hotel_photos" v-bind:key="_uid+image_index">
              <a class="carousel__modal-anchor"
                 :href="image.url_original"
                 :data-pswp-width="get_pswp_size(image_index, 'width')"
                 :data-pswp-height="get_pswp_size(image_index, 'height')"
                 :class="image_index === current_slide_index && 'carousel__modal-anchor--active'"
              >
                <img class="carousel__modal-img" :ref="'img_ref_' + image_index" @load="get_image_size(image_index)" :src="image.url_original" />
              </a>
            </div>
          </div>

        </vb-modal>

      </div>
    </div>
  </section>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js'
import 'photoswipe/dist/photoswipe.css'

export default {
  name: 'carousel',

  props: {
    hotels: Array,
  },

  components: {
  },

  data() {
    return {
      lightbox: null,
      current_slide_index: -1,
      image_sizes: [],
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {

    this.$root.$on('bv::modal::shown', () => {

      // zoom on click
      if (!this.lightbox) {
        this.lightbox = new PhotoSwipeLightbox({
          showHideAnimationType: 'fade',
          gallery: '.carousel__modal',
          children: 'a',
          pswpModule: () => import('photoswipe'),
        })
        this.lightbox.init()
      }

      this.lightbox.on('close', () => {

        this.current_slide_index = this.lightbox.pswp.currIndex
        const current_image = this.$refs['img_ref_' + this.current_slide_index][0]

        if (current_image) current_image.scrollIntoView({ behavior: 'smooth', block: 'start' })
      });

    })

    this.$root.$on('bv::modal::hide', () => {
      if (this.lightbox) {
        this.lightbox.destroy()
        this.lightbox = null
        this.current_slide_index = -1
      }
    })
  },

  unmounted() {
  },  

  methods: {

    get_image_size(image_index) {

      const image_node = this.$refs['img_ref_' + image_index][0]

      if (image_node) {

        if (!this.image_sizes.some(size => size.index === image_index)) { //evito la ricorsione infinita
          this.image_sizes.push({
            index: image_index,
            width: image_node.naturalWidth,
            height: image_node.naturalHeight
          });

          this.image_sizes.sort((a, b) => a.index - b.index)
        }
      }
    },

    get_pswp_size(image_index, sizes) {
      const size = this.image_sizes.find(size => size.index === image_index)
      return size ? size[sizes] : null
    },
  }
}
</script>

<style lang="scss" scoped>

  .carousel {
    margin-top: $grid-gutter-width/2;

    @include media-breakpoint-up(sm){
      margin-top: $grid-gutter-width;
    }

    &__main {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0;
      position: relative;

      @include media-breakpoint-up(md){
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
      }
    }

    &__item {
      border-radius: 10px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;

      &--1 {
        grid-area: 1 / 1 / 3 / 3;
        height: 270px;

        @include media-breakpoint-up(xl){
          height: 385px;
        }
      }
      &--2 {
        grid-area: 1 / 3 / 2 / 4;
        height: 130px;
        display: none;

        @include media-breakpoint-up(md){
          display: block
        }

        @include media-breakpoint-up(xl){
          height: 187px;
        }
      }
      &--3 {
        grid-area: 1 / 4 / 2 / 5;
        height: 130px;
        display: none;

        @include media-breakpoint-up(md){
          display: block
        }

        @include media-breakpoint-up(xl){
          height: 187px;
        }
      }
      &--4 {
        grid-area: 2 / 4 / 3 / 5;
        height: 130px;
        display: none;

        @include media-breakpoint-up(md){
          display: block
        }

        @include media-breakpoint-up(xl){
          height: 187px;
        }
      }
      &--5 {
        grid-area: 2 / 3 / 3 / 4;
        height: 130px;
        display: none;

        @include media-breakpoint-up(md){
          display: block
        }

        @include media-breakpoint-up(xl){
          height: 187px;
        }
      }
      &--6 {
        grid-area: 1 / 5 / 3 / 6;
        height: 270px;
        display: none;

        @include media-breakpoint-up(md){
          display: block
        }

        @include media-breakpoint-up(xl){
          height: 385px;
        }
      }
    }

    &__show {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: $white;
      position: absolute;
      bottom: 15px;
      right: 15px;
      border-radius: 5px;
      border: 1px solid $dark;
      padding: 5px 8px;

      &-icon {
        margin-right: 8px;
      }

      &-text {
        color: $dark;
        font-size: $font-size-md;
        font-weight: $vb-font-weight-semibold;
      }
    }

    &__modal {

      &-wrap {
        display: grid;
        grid-template-columns:repeat(3,1fr);
        grid-gap: 10px;

        @include media-breakpoint-up(lg) {
          grid-template-columns:repeat(6,1fr);
        }

        @include media-breakpoint-up(xl) {
          grid-template-columns:repeat(8,1fr);
        }

        @include media-breakpoint-up(xxl) {
          grid-template-columns:repeat(10,1fr);
        }
      }

      &-item {
        aspect-ratio:1/1;
      }

      &-anchor {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        position: relative;

        @keyframes flash {
          0%, 100% {
            border-color: $secondary; // Colore del bordo durante il lampeggio
          }
          50% {
            border-color: transparent; // Colore alternativo durante il lampeggio
          }
        }

        &:after {
          $width_abs: 0;
          position: absolute;
          top: $width_abs;
          left: $width_abs;
          right: $width_abs;
          bottom: $width_abs;
          transition: $transition-base;
          z-index: 1;
          border: 5px solid transparent;
          border-radius: 5px;
          box-shadow: 0 0 10px 2px rgba(0,0,0, 0.2) inset;
        }

        &--active {

          img {
            opacity: .4;
            transition: all 1s ease 0s;
            transform: scale(1.1)
          }

          &:after {
            content: "";
            animation: flash 1s infinite;
          }
        }
      }

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .2s ease;

        &:hover {
          opacity: .5;
          transform: scale(1.1)
        }
      }
    }
  }

</style>