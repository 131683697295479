<template>
  <div class="rooms-info">
    <div class="rooms-info__item" v-if="room.room_info.room_size.metre_square !== ''">
      <p class="rooms-info__content">
        <vb-icon class="rooms-info__icon" :name="'zoom'" :color="sass('primary')" :size="15" />
        <span class="rooms-info__name" v-html="'Superficie di ' + room.room_info.room_size.metre_square + 'm<sup>2</sup>'" />
      </p>
    </div>
    <div class="rooms-info__item" v-if="room.room_info.bathroom_count > 0">
      <p class="rooms-info__content">
        <vb-icon class="rooms-info__icon" :name="'shower'" :color="sass('primary')" :size="15" />
        <span class="rooms-info__name" v-html="room.room_info.bathroom_count + (room.room_info.bathroom_count > 1 ? ' Bagni' : ' Bagno') + ' in camera'" />
      </p>
    </div>
    <div class="rooms-info__item" v-if="room.room_info.room_type !== ''">
      <p class="rooms-info__content">
        <vb-icon class="rooms-info__icon" :name="'door'" :color="sass('primary')" :size="15" />
        <span class="rooms-info__name" v-html="'Tipologia: ' + room.room_info.room_type" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rooms-info',

  components: {
  },

  props: {
    room: Object
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .rooms-info {
    $this: &;
    align-items: center;
    display: none;

    @include media-breakpoint-up(md){
      display: flex;
    }

    &__item {
      margin-left: $grid-gutter-width/2;

      &:first-child {
        margin-left: 0;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    &__name {
      margin-left: 5px;
      font-size: $font-size-sm;
    }
  }

</style>