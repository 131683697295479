<template>
  <div class="results-content-block">
    <div class="results-content-block__not-found" v-if="hotels.length === 0 && !searching && !appending">
      <not-found>
        <span class="semibold text-primary">Siamo spiacenti!</span><br>
        <span>Strutture non disponibili per le tue date. <strong class="semibold">Cambia i criteri di ricerca.</strong></span>
      </not-found>
    </div>
    <dynamic-scroller
        page-mode
        ref='results-scroller'
        :items="hotels"
        :item-size="null"
        :min-item-size="100"
        key-field="hotel_id">
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          class="pb-4"
          :item="item"
          :data-index="index"
          :active="active">
          <pad :hotel="item" />
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import NotFound from '@/components/blocks/not-found'
import Pad from '@booking/components/blocks/pad.vue'

export default {
  name: "results-content-block",

  components: {
    'not-found': NotFound,
    'pad': Pad
  },

  props: {
    hotels: {
      type: Array,
      default: () => { return [] }
    },
    searching: Boolean,
    appending: Boolean,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .results-content-block {

    &__not-found {
      border-radius: 10px;
      background-color: $white;
      padding: 20px 30px;
      display: flex;
    }
  }

</style>