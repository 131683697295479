<template>
  <div class="map">
    <div id="map" ref="map"></div>
  </div>
</template>

<script>
/**
 * TODO: la info-window può diventare un componente Vue: http://blog.olamisan.com/how-to-create-infowindow-component-in-vuejs
 */
import { number } from 'mathjs'
export default {
  name: "map-content-block",

  components: {
  },

  props: {
    map_location: {
      type: Object,
      default: () => {
        return {
          lat: "0",
          lng: "0",
        }
      }
    },
    map_markers: {
      type: Array,
      default: () => { return [] }
    },
    map_style: {
      type: Object,
      default: () => { return {} }
    },
    map_info_window: {
      type: Object,
      default: () => { return {} }
    }
  },

  data() {
    return {
      google_map: {},
      info_window: {},
      gm_markers: [],
      gm_options: {
      scrollwheel: true,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
        options:{
          gestureHandling: 'greedy',  // zoom without ctrl+
          mapId: this.get_config().googleMaps.mapId,
        },
        // map location
        center: {
          lat: number(this.map_location.latitude),
          lng: number(this.map_location.longitude),
        },
      },
    }
  },

  computed: {   
    map() {
      return this.$refs.map
    },
  },

  created() {
  },

  beforeMount() {   
  },

  mounted() {
    const api_key = this.get_config().googleMaps.apikey
    // https://dev.to/tareqnewazshahriar/how-to-add-google-map-api-script-in-a-vue-project-without-plugins-3ldi
    window.checkAndAttachMapScript = function(callback) {
      let scriptId = "map-api-script"
      let mapAlreadyAttached = !!document.getElementById(scriptId)
      if (mapAlreadyAttached) {
        if (window.google) callback()
      }
      else {
        window.mapApiInitialized = callback
        let script = document.createElement('script')
        script.id = scriptId
        script.src = `https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=places,geometry&callback=mapApiInitialized`
        document.body.appendChild(script)
      }
      return mapAlreadyAttached
    }    
    window.checkAndAttachMapScript(this.init_map)
  },

  watch: {
    /*
    map_markers(new_value, old_value) {
      if(new_value !== old_value) {
        this.$nextTick(() => {
          this.init_map()
        })
      }
    }
    */
  },

  methods: {

    init_map() {
      if (this.map == undefined) {
        // evito un potenziale undefined nelle $refs
        setTimeout(() => this.init_map(), 250)
      }
      else {
        this.render_map()
        this.render_markers()
      }
    },

    render_map() {
      this.google_map = new window.google.maps.Map(
        this.map,
        this.gm_options,
      )
    },

    async render_markers() {
      let existing_markers = this.gm_markers.length
      const {AdvancedMarkerElement} = await window.google.maps.importLibrary("marker")
      this.map_markers.forEach((item, index) => {
        if(index<existing_markers) return

        let marker_image = document.createElement('img');
        marker_image.src = item.icon.url
        
        let marker = new AdvancedMarkerElement({
            map: this.google_map,
            zIndex: item.z_index ? item.z_index : 1,
            title: item.name,
            position: item.position,
            content: marker_image,
          })

        if (this.gm_markers.length > 1) { // nella detail NON voglio l'info window né l'effetto hover
          marker.addListener('click', () => {
            if (this.info_window.close) this.info_window.close()
            let googleMapInfoWindow = new window.google.maps.InfoWindow({
              position: item.position,
              content: '<div id="info_window_id">'+item.content+'</div>',
              maxWidth: 260,
              pixelOffset: new window.google.maps.Size(
                this.map_info_window.offset ? this.map_info_window.offset.x : 0,
                this.map_info_window.offset ? this.map_info_window.offset.y : 0,
              )
            })
            googleMapInfoWindow.setMap(this.google_map)
            googleMapInfoWindow.close(this.google_map)
            window.google.maps.event.addListener(googleMapInfoWindow, 'domready', () => {
              let iw = document.getElementById('info_window_id')
              iw.addEventListener('click', () => { 
                this.$emit('item-clicked', {
                  index: index,
                  item: this.map_markers[index].item,
                })
              })
            })
            this.info_window = googleMapInfoWindow                      
            this.info_window.open(this.google_map)
          })
        }

        this.gm_markers[index] = marker
        this.gm_markers[index].setMap(this.google_map)
      })
      
      if (this.gm_markers.length <= 1) {
        this.google_map.setZoom(14)
        return
      }

      let bounds = new window.google.maps.LatLngBounds()
      this.map_markers.forEach(m => {
        bounds.extend(new window.google.maps.LatLng(m.position))
      })
      this.google_map.fitBounds(bounds)
    },
  },
};
</script>

<style lang="scss" scoped>
  .map, #map {
    display: block;
    width: 100%;
    height: 100%;
  }
</style>